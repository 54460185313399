import { FormControl, Slider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export interface RangeValue {
  value: number,
  label: string,
}

interface RangeSliderProps {
  name: string;
  range: RangeValue[];
}

const RangeSliderFilter = ({ name, range }: RangeSliderProps) => {
  const { control, getValues } = useFormContext();
  const value = getValues()[name];

  return (
    <div className='range-slider-filter w-full'>
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          defaultValue={[0, range.length - 1]}
          render={({ field }) =>
          <Slider
              step={1}
              min={0}
              max={range.length - 1}
              {...field}
              valueLabelDisplay="off"
            />
          }
        />
      </FormControl>
      <div className='flex w-full justify-between'>
        <div className='range-slider__min'>{range[value[0]].label}</div>
        <div className='range-slider__max'>{range[value[1]].label}</div>
      </div>
    </div>
  )
};

export default RangeSliderFilter;
