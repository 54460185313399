import './style/app.scss';
import './style/index.scss';

import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

import logoIcon from './assets/logo-icon.svg';
import logo from './assets/logo.png';

import { SideScroller } from './components/side-scroller';
import ChangePasswordPage from './pages/change-password-page';
import DisclosuresPage from './pages/disclosures-page';
import LoginPage from './pages/login-page';
import SignUpPage from './pages/sign-up-page';
import TermsOfServicePage from './pages/terms-of-service-page';
import StonkRoutes from './widgets/stonk-routes';
import UserActions from './widgets/user-actions';
import TrackerPage from './pages/tracker-page';
import BetaGuard from './widgets/beta-guard';
import LeaderboardPage from './pages/leaderboard-page';

const App = () => {
  return (
    <div className='app'>
      <Router>
        <SideScroller>
          <div className='app__bar'>
            <h1 className='app__logo'>
              <Link to='/'>
                <img
                  src={logo}
                  alt='StonkFinder'
                />
              </Link>
            </h1>
            <div className='app__user'>
              <BetaGuard>
                <Link className='app__beta' to='/tracker/leaderboard/dayRatio'>
                  Leaderboards
                </Link>
              </BetaGuard>
              <UserActions />
            </div>
          </div>
        </SideScroller>
        <div className='app__content'>
          <Switch>
            <Route path='/disclosures'>
              <DisclosuresPage />
            </Route>
            <Route path='/terms-of-service'>
              <TermsOfServicePage />
            </Route>
            <Route path='/login'>
              <LoginPage />
            </Route>
            <Route path='/sign-up'>
              <SignUpPage />
            </Route>
            <Route path='/change-password'>
              <ChangePasswordPage />
            </Route>
            <Route path='/tracker/user/:user'>
              <BetaGuard>
                <TrackerPage />
              </BetaGuard>
            </Route>
            <Route path='/tracker/leaderboard/:metric'>
              <BetaGuard>
                <LeaderboardPage />
              </BetaGuard>
            </Route>
            <StonkRoutes />
          </Switch>
        </div>
        <SideScroller>
          <div className='app__footer flex justify-between items-center'>
            <p className='ml-6'>we don’t know what the f*** we’re doing, we’re also financially irresponsible... for more information see our <Link className='link' to='/disclosures'>Disclosures</Link></p>
            <div className='app__footer__logo mr-6'>
              <Link to='/'>
                <img
                  src={logoIcon}
                  alt='StonkFinder'
                />
              </Link>
            </div>
          </div>
        </SideScroller>
      </Router>
    </div>
  );
};

export default App;
