import * as ActionTypes from './action-types';
import { SetAction } from './action/actions';

export function error(state: unknown, action: SetAction) {
  switch (action.type) {
    case ActionTypes.ERROR_DID_CHANGE:
      return action.payload;
    default:
      return state;
  }
}

export function loadProgress(state: unknown, action: SetAction) {
  switch (action.type) {
    case ActionTypes.LOAD_PROGRESS_DID_CHANGE:
      return action.payload;
    default:
      return state;
  }
}

export function stonks(state: unknown, action: SetAction) {
  switch (action.type) {
    case ActionTypes.STONKS_DID_CHANGE:
      return action.payload;
    default:
      return state;
  }
}

export function user(state: unknown, action: SetAction) {
  switch (action.type) {
    case ActionTypes.USER_DID_CHANGE:
      return action.payload;
    default:
      return state;
  }
}
