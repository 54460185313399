import classNames from "classnames";
import numeral from "numeral";

import { Stonk } from "../../model/stonk";
import SortIcon from "../table/sort-icon";

class PriceDeltaAdapter {
  selector: (stonk: Stonk) => any;
  constructor(selector: (stonk: Stonk) => any) {
    this.selector = selector;
  }

  getTableHeader(title, sortStonks, sortColumn: boolean, asc: boolean) {
    return <p className='cursor-pointer' onClick={sortStonks}>
      {title} <SortIcon sortColumn={sortColumn} asc={asc} />
    </p>;
  }

  getView(stonk: Stonk) {
    const value = this.selector(stonk);
    const classList = classNames(
      'text-center',
      { positive: value > 0 },
      { negative: value < 0 },
      { neutral: value === 0 }
    );

    return <p className={classList} title={value}>{numeral(value).format('$0.00a')}</p>;
  }
}

export default PriceDeltaAdapter;
