import { Store } from 'redux';
import { setUser } from '../model/action/actions';
import { getUser } from '../model/selector/selectors';
import { ApiService } from '../service/api-service';

export class BookmarkController {
  private store: Store;
  private apiService: ApiService;

  constructor(store: Store, apiService: ApiService) {
    this.store = store;
    this.apiService = apiService;
  }

  addBookmark(symbol: string) {
    const state = this.store.getState();
    const originalUser = { ...getUser(state) };
    const user = { ...getUser(state) };
    if (user.bookmarks.includes(symbol) === false) {
      user.bookmarks = [...user.bookmarks, symbol];
    }
    this.store.dispatch(setUser(user));
    
    return this.apiService.addBookmark(symbol).catch(() => {
      this.store.dispatch(setUser(originalUser));
    });
  }

  removeBookmark(symbol: string) {
    const state = this.store.getState();
    const originalUser = { ...getUser(state) };
    const user = { ...getUser(state) };
    const bookmarkIndex = user.bookmarks.indexOf(symbol);
    if (bookmarkIndex > -1) {
      const bookmarks = [...user.bookmarks];
      bookmarks.splice(bookmarkIndex, 1);
      user.bookmarks = bookmarks;
    }
    this.store.dispatch(setUser(user));
    
    return this.apiService.removeBookmark(symbol).catch(() => {
      this.store.dispatch(setUser(originalUser));
    });
  }
}
