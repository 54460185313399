import '../style/stonk-table.scss';

import { Stonk } from '../../model/stonk';

const StonkTable = ({ stonks, sortBy, asc, sortColumn, metrics = [], maxEntries = 100 }) => {
  const sortMetric = metrics.find(metric => metric.id === sortColumn);
  let sortedStonks = [...stonks];
  if (sortedStonks !== null) {
    if (sortMetric !== undefined && sortMetric.sort !== undefined) {
      sortedStonks.sort(sortMetric.sort);
      if (asc === false) {
        sortedStonks.reverse();
      }
    }
    if (maxEntries > 0) {
      sortedStonks = sortedStonks.slice(0, maxEntries);
    }
  }

  return (
    <table className="stonk-table__table w-full border-separate space-y-2 text-sm">
      <thead>
        <tr className='stonk-table__table-header stonk-table__row'>
          {metrics.map(metric =>
            <th key={metric.id} className='stonk-table__table-header__column'>
              <div className='stonk-table__column p-2 md:p-3' title={metric.description}>
                {metric.adapter.getTableHeader(metric.text, () => sortBy(metric.id), metric.id === sortColumn, asc)}
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {sortedStonks !== null && sortedStonks.map((stonk: Stonk, indexR) =>
          <tr key={indexR} className='stonk-table__row'>
            {metrics.map(( metric, indexC ) =>
              <td key={indexC}>
                <div className='stonk-table__column p-2 md:p-3' title={metric.description}>
                  {metric.adapter.getView(stonk)}
                </div>
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default StonkTable;
