import classNames from "classnames";
import numeral from "numeral";

import { Stonk } from "../../model/stonk";
import SortIcon from "../table/sort-icon";

class NumberCompareAdapter {
  constructor(
    private selector: (stonk: Stonk) => any, 
    private compareSelector: (stonk: Stonk) => any) {
  }

  getTableHeader(title, sortStonks, sortColumn: boolean, asc: boolean) {
    return <p className='cursor-pointer' onClick={sortStonks}>
      {title} <SortIcon sortColumn={sortColumn} asc={asc} />
    </p>;
  }

  getView(stonk: Stonk) {
    const value = this.selector(stonk);
    const compareValue = this.compareSelector(stonk);
    let percentage = null;
    if (value > 0) {
      percentage = Math.round(compareValue / value * 100) - 100;
    }
    const classList = classNames(
      { positive: compareValue > value },
      { negative: compareValue < value },
      { neutral: compareValue === value }
    );
    return <div className='flex w-f'>
      <div className='flex-grow justify-center text-center' title={value}>
        {numeral(value).format('0.0a')}
      </div>
      <div className='flex-grow justify-center text-center'>
        <p className={classList}>({percentage}%)</p>
      </div>
    </div>;
  }
}

export default NumberCompareAdapter;
