import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { metrics } from '../../model/metrics';

const MetricFilter = () => {
  const { register, getValues } = useFormContext();
  const values = getValues();

  return (
    <div className='metric-filter'>
      <div className='flex flex-col justify-start flex-wrap'>
        {metrics.map(metric => {
          if (!metric.locked) {
            return (
              <div className='inline-flex' title={metric.description} key={metric.id}>
                <FormControlLabel className='inline-flex' control={<Checkbox {...register('metric')} value={metric.id} checked={values.metric.includes(metric.id)} size='small' />} label={metric.text} />
              </div>
            );
          } else {
            return null;
          }
        }
        )}
      </div>
    </div>
  )
};

export default MetricFilter;
