import '../style/detail-page.scss';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import numeral from 'numeral';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Stonk } from '../../model/stonk';
import { formatText } from '../../utils/format-text';
import SectorAdapter from '../adapter/sector-adapter';
import BackButton from '../components/back-button';
import Graph from '../components/graph';
import StockTwits from '../components/stocktwits';
import Loader from '../widgets/loader';
import NotFoundPage from './not-found';
import { faGoogle, faYahoo } from '@fortawesome/free-brands-svg-icons';

const DetailPage = ({ stonks }) => {
  const match = useRouteMatch<{ symbol: string }>();
  const symbol = match.params.symbol;
  let stonk: Stonk = null;

  if (stonks !== null && stonks.length > 0) {
    stonk = stonks.find((stonk) => stonk.symbol === symbol.toUpperCase());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    window['gtag']('event', 'page_view', {
      page_title: `Details ${symbol}`,
      page_location: symbol,
      page_path: symbol
    })
  });

  return stonk !== undefined && stonk !== null ? (
    <div className='detail-page'>
      <div className='detail-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='detail-page__container'>
        <div className='detail-page__stonk-header mb-12'>
          <div className='detail-page__stonk-header-name'>
            <p className='detail-page__title'>{stonk.overview.Name}</p>
            <div className='detail-page__stonk-subheader'>
              {stonk.overview.Exchange}: {stonk.overview.Symbol} | Asset type: {stonk.overview.AssetType}
            </div>
          </div>
        </div>
        <div className='mb-24 grid grid-cols-1 gap-14'>
          <div className='detail-page__graph'>
            <Graph stonk={stonk} />
          </div>
        </div>
        <div className='detail-page__content mb-24 grid grid-cols-1 lg:grid-cols-3 gap-14'>
          <div className='detail-page__details'>
            <p className='detail-page__about mb-2'>About</p>
            <div className='mb-8 w-full'>{stonk.overview.Description}</div>
            <div className='grid gap-4 grid-cols-2'>
              <div>
                <div className='detail-page__metric-label mb-1'>CIK</div>
                <div>{stonk.overview.CIK}</div>
              </div>
              <div>
                <div className='detail-page__metric-label mb-1'>Exchange</div>
                <div>{stonk.overview.Exchange}</div>
              </div>
              <div>
                <div className='detail-page__metric-label mb-1'>Currency</div>
                <div>{stonk.overview.Currency}</div>
              </div>
              <div>
                <div className='detail-page__metric-label mb-1'>Country</div>
                <div>{stonk.overview.Country}</div>
              </div>
              <div>
                <div className='detail-page__metric-label mb-1'>Sector</div>
                <div className='w-fit'>
                  {new SectorAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.Sector).getView(
                    stonk,
                  )}
                </div>
              </div>
              <div>
                <div className='detail-page__metric-label mb-1'>Industry</div>
                <div>{formatText(stonk.overview.Industry)}</div>
              </div>
              <div className='col-span-2'>
                <div className='detail-page__metric-label mb-1'>Address</div>
                <div>{stonk.overview.Address}</div>
              </div>
              <div className='col-span-2 detail-page__links'>
                <div className='detail-page__metric-label mb-1'>Links</div>
                <a
                  href={`https://www.google.com/finance/quote/${stonk.symbol}:${stonk.overview.Exchange}`}
                  target='_blank' rel="noreferrer"
                >
                  <FontAwesomeIcon size='lg' icon={faGoogle} />
                </a>
                <a
                  href={`https://finance.yahoo.com/quote/${stonk.symbol}`}
                  target='_blank' rel="noreferrer"
                >
                  <FontAwesomeIcon size='lg' className='ml-2' icon={faYahoo} />
                </a>
              </div>
            </div>
          </div>
          <div className='detail-page__accordions lg:col-span-2'>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                <Typography>Summary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='grid gap-4 grid-cols-4'>
                  <div>
                    <div className='detail-page__metric-label mb-1'>52 Week Low</div>
                    <div>{numeral(stonk.overview['52WeekLow']).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>52 Week High</div>
                    <div>{numeral(stonk.overview['52WeekHigh']).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>50 Day Moving Average</div>
                    <div>{numeral(stonk.overview['50DayMovingAverage']).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>200 Day Moving Average</div>
                    <div>{numeral(stonk.overview['200DayMovingAverage']).format('$0,0.00')}</div>
                  </div>

                  <div>
                    <div className='detail-page__metric-label mb-1'>Shares Outstanding</div>
                    <div>{numeral(stonk.overview.SharesOutstanding).format('0,0')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>50 Day Moving Average</div>
                    <div>{stonk.overview.DividendDate}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>200 Day Moving Average</div>
                    <div>{stonk.overview.ExDividendDate}</div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                <Typography>Key Data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='grid gap-4 grid-cols-4'>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Fiscal Year End</div>
                    <div>{stonk.overview.FiscalYearEnd}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Latest Quarter</div>
                    <div>{stonk.overview.LatestQuarter}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Market Capitalization</div>
                    <div>{numeral(stonk.overview.MarketCapitalization).format('0,0')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>EBITDA</div>
                    <div>{numeral(stonk.overview.EBITDA).format('0,0')}</div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                <Typography>Stats</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='grid gap-4 grid-cols-4'>
                  <div>
                    <div className='detail-page__metric-label mb-1'>PE Ratio</div>
                    <div>{numeral(stonk.overview.PERatio).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>PEG Ratio</div>
                    <div>{numeral(stonk.overview.PEGRatio).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Book Value</div>
                    <div>{numeral(stonk.overview.BookValue).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Dividend Per Share</div>
                    <div>{numeral(stonk.overview.DividendPerShare).format('0,0.0')}</div>
                  </div>

                  <div>
                    <div className='detail-page__metric-label mb-1'>Dividend Yield</div>
                    <div>{numeral(stonk.overview.DividendYield).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>EPS</div>
                    <div>{numeral(stonk.overview.EPS).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Revenue Per Share TTM</div>
                    <div>{numeral(stonk.overview.RevenuePerShareTTM).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Profit Margin</div>
                    <div>{numeral(stonk.overview.ProfitMargin).format('$0,0.00')}</div>
                  </div>

                  <div>
                    <div className='detail-page__metric-label mb-1'>Operating Margin TTM</div>
                    <div>{numeral(stonk.overview.OperatingMarginTTM).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Return On Assets TTM</div>
                    <div>{numeral(stonk.overview.ReturnOnAssetsTTM).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Return On Equity TTM</div>
                    <div>{numeral(stonk.overview.ReturnOnEquityTTM).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Revenue TTM</div>
                    <div>{numeral(stonk.overview.RevenueTTM).format('$0,0.00')}</div>
                  </div>

                  <div>
                    <div className='detail-page__metric-label mb-1'>Gross Profit TTM</div>
                    <div>{numeral(stonk.overview.GrossProfitTTM).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Diluted EPSTTM</div>
                    <div>{numeral(stonk.overview.DilutedEPSTTM).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Quarterly Earnings Growth YOY</div>
                    <div>{numeral(stonk.overview.QuarterlyEarningsGrowthYOY).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Quarterly Revenue Growth YOY</div>
                    <div>{numeral(stonk.overview.QuarterlyRevenueGrowthYOY).format('0.00')}</div>
                  </div>

                  <div>
                    <div className='detail-page__metric-label mb-1'>Analyst Target Price</div>
                    <div>{numeral(stonk.overview.AnalystTargetPrice).format('$0,0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Trailing PE</div>
                    <div>{numeral(stonk.overview.TrailingPE).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Forward PE</div>
                    <div>{numeral(stonk.overview.ForwardPE).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Price To Sales Ratio TTM</div>
                    <div>{numeral(stonk.overview.PriceToSalesRatioTTM).format('0.00')}</div>
                  </div>

                  <div>
                    <div className='detail-page__metric-label mb-1'>Price To Book Ratio</div>
                    <div>{numeral(stonk.overview.PriceToBookRatio).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>EV To Revenue</div>
                    <div>{numeral(stonk.overview.EVToRevenue).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>EV To EBITDA</div>
                    <div>{numeral(stonk.overview.EVToEBITDA).format('0.00')}</div>
                  </div>
                  <div>
                    <div className='detail-page__metric-label mb-1'>Beta</div>
                    <div>{numeral(stonk.overview.Beta).format('0.00')}</div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className='mt-4'>
              <StockTwits symbol={symbol.toUpperCase()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : stonk === null ? (
    <Loader
      loading={true}
      children={null}
    />
  ) : (
    <NotFoundPage />
  );
};

export default DetailPage;
