import { createSelector } from 'reselect';

import * as Constants from '../constants';
import { getUser } from './selectors';

export function isUserBeta() {
  return createSelector(getUser, 
    (user) => {
      let beta = false;

      if (user !== null) {
        beta = user.roles.includes(Constants.ROLE_BETA);
      }

      return beta;
    }
  );
}
