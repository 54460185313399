import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createFormReference } from '../../utils/form-reference';

export const LoginForm = ({ login }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(null);

  const onSubmit = (data) => {
    setError(null);
    return login(data.email, data.password).catch((exception) =>
      setError(exception.message),
    );
  };

  return (
    <form id='login-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='login-form'>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type='text'
              size='small'
              variant='outlined'
              label='Email'
              {...createFormReference('email', register, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type='password'
              size='small'
              variant='outlined'
              label='Password'
              {...createFormReference('password', register, errors)}
            />
          </Grid>
          {error !== null ? (
            <Grid item xs={12}>
              <Typography color='error'>{error}</Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              size='small'
              type='submit'
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
