import '../style/change-password-page.scss';

import BackButton from '../components/back-button';
import { ChangePasswordForm } from '../forms/change-password-form';
import { useMainController } from '../hooks/use-main-controller';

const ChangePasswordPage = () => {
  const mainController = useMainController();

  const changePassword = (password: string, newPassword: string) => {
    return mainController.getAuthController().changePassword(password, newPassword);
  };

  return (
    <div className='change-password-page'>
      <div className='change-password-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='change-password-page__container p-8 text-center'>
        <h1 className='change-password-page__title mb-8'>Change Password</h1>
        <div className='change-password-page__form'>
          <ChangePasswordForm changePassword={changePassword} />
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
