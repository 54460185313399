import { createContext, useContext } from 'react';

import MainController from '../../controller/main-controller';

const MainControllerContext = createContext(null);

export const MainControllerProvider = MainControllerContext.Provider;
export function useMainController(): MainController {
  return useContext(MainControllerContext);
}
