import { volumeRange } from '../../model/volume-range';
import RangeSliderFilter from './range-slider-filter';

const VolumeFilter = () => {
  return (
    <div className='volume-range-filter'>
      <p>Volume</p>
      <div className='flex flex-wrap w-full p-4'>
        <RangeSliderFilter name='volume' range={volumeRange}/>
      </div>
    </div>
  )
};

export default VolumeFilter;
