import { Action, applyMiddleware, compose, createStore } from 'redux';
// import reduxThunkMiddleware from 'redux-thunk';

import { PersistMiddleware } from '../utils/persist-middleware';
import * as Constants from '../model/constants';
import { Logger } from '../utils/logger';
import { PersistedStorage } from '../utils/persisted-storage';
import { ResetEnhancer } from '../utils/reset-enhancer';
import * as ActionTypes from './action-types';
import { AppState, initialState, persistActions, resetStateKeys } from './app-state';
import * as Reducers from './reducers';

export function getNewStore(initialState: AppState) {
  const globalReducer = (state: unknown, action: Action) => {
    const reducers = {};
    for (const reducerKey in Reducers) {
      reducers[reducerKey] = Reducers[reducerKey](state[reducerKey], action);
    }
    return reducers;
  };
  const composeEnhancers =
    (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) ||
    compose;
  const persistMiddleware = new PersistMiddleware(persistActions);
  const middleWareEnhancer = applyMiddleware(
    // reduxThunkMiddleware,
    persistMiddleware.createMiddleware(),
  );
  const resetEnhancer = new ResetEnhancer(getDefaultState());
  resetEnhancer.setAction(ActionTypes.RESET_APPLICATION, resetStateKeys);

  return createStore(
    globalReducer,
    initialState,
    composeEnhancers(middleWareEnhancer, resetEnhancer.create()),
  );
}

export function getDefaultState(): AppState {
  return initialState;
}

export function getPersistedStorageState() {
  let value;
  let stateKey: string;
  const state = {};
  const persistedStorage = new PersistedStorage(
    Constants.PERSISTED_STORAGE_NAMESPACE,
  );
  const logger = new Logger('Store');
  // Persistence
  if (persistedStorage.isEnabled() === true) {
    for (const key in persistActions) {
      stateKey = persistActions[key];
      value = persistedStorage.getProperty(stateKey);
      if (value !== undefined) {
        logger.log(
          `"${stateKey}" has been restored from the cache with a value:`,
          value,
        );
        state[stateKey] = value;
      }
    }
  }
  return state;
}

export function getInitialState(): AppState {
  return { ...getDefaultState(), ...getPersistedStorageState() };
}
