/**
 * Redux store enhancer to reset the state. The enhancer provides API to reset the state partially (particular fields).
 * The enhancer works only with the state management which relies on the initial state.
 * In order to reset the state Flux Standard Action with the type "reset" should be dispatched.
 *
 * @module class-reset-enhancer
 * @category Reset Enhancer
 * @example
 * import {createStore} from 'redux';
 * import {ResetEnhancer} from 'vpe-utils/lib/web/reset-enhancer';
 *
 * import * as Reducers from './reducers';
 *
 * let store;
 * let enhancer = new ResetEnhancer();
 * let myInitialState = {run: 'fast'};
 *
 * enhancer.setAction('run', ['run']);
 *
 * store = createStore(Reducers, myInitialState, enhancer.create());
 * // Later it's possible to reset the state
 * store.dispatch({type: 'reset'});
 */

import { StoreEnhancer } from "redux";

/**
 * @alias module:class-reset-enhancer
 */
class ResetEnhancer {
  private actions: unknown;
  private defaultState: unknown;
  private resetType: string;

  constructor(defaultState) {
    this.resetType = 'reset';
    this.actions = {};
    this.defaultState = defaultState;
  }

  /**
   * Creates an instance of the Redux enhancer.
   *
   * @returns {Object} redux store enhancer
   */
  create(): StoreEnhancer {
    return ((next) => (reducer, initialState) => {
      return next((state, action) => this.getState(state, action, reducer), initialState);
    }) as StoreEnhancer;
  }

  getState(state, action, reducer) {
    // By default try to return the state without any changes
    let update = state;
    const resetProperties = this.actions[action.type];
    if (action.type === this.resetType) {
      // Reset state to the initial state
      update = this.defaultState;
    } else if (resetProperties !== undefined) {
      // Cherry-pick the state properties
      update = this.updateProperties(resetProperties, this.defaultState, state);
    }
    return reducer(update, action);
  }

  /**
   * Registers the action type with the list of properties to reset.
   * Every time when action type will be dispatched, store properties will be updated to the initial state.
   *
   * @param {string} actionType Flux Standard Action type
   * @param {Array} storeProperties list of high level properties to reset in the store
   * @returns {ResetEnhancer} reference on the current instance to support multiple invocations
   */
  setAction(actionType, storeProperties) {
    this.actions[actionType] = storeProperties;
    return this;
  }

  updateProperties(properties, defaultState, currentState) {
    const result = { ...currentState };
    properties.forEach((property) => {
      result[property] = defaultState[property];
    });
    return result;
  }
}

export { ResetEnhancer };
