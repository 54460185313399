import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { presetList } from '../../model/presets';

const Presets = () => {
  const { control } = useFormContext();

  return (
    <div className='presets'>
      <FormControl fullWidth>
        <InputLabel id='presets' size='small'>Presets</InputLabel>
        <Controller
          name='presets'
          control={control}
          render={({ field }) => <Select
            {...field}
            labelId='presets'
            fullWidth
            size='small'
            label='Presets'
          >
            {
              presetList.map((preset, index) => 
                <MenuItem key={index} value={index}>{preset.label}</MenuItem>
              )
            }
            <MenuItem value={-1}>Custom</MenuItem>
          </Select>}
        />
      </FormControl>
    </div>
  )
};

export default Presets;
