import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { createFormReference } from '../../utils/form-reference';

export const ChangePasswordForm = ({
  changePassword,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const onSubmit = (data) => {
    setError(null);
    setMessage(null);

    return changePassword(data.password, data.newPassword)
      .then((result) => setMessage(result.message))
      .catch((exception) => {
        setError(exception.message);
      });
  };

  return (
    <form id='change-password-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='change-password-form'>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type='password'
              size='small'
              variant='outlined'
              label='Current Password'
              {...createFormReference('password', register, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type='password'
              size='small'
              variant='outlined'
              label='New Password'
              {...createFormReference('newPassword', register, errors)}
            />
          </Grid>
          {error !== null ? (
            <Grid item xs={12}>
              <Typography color='error'>{error}</Typography>
            </Grid>
          ) : null}
          {message !== null ? (
            <Grid item xs={12}>
              <Typography color='primary'>{message}</Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              size='small'
              type='submit'
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
