import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useFormContext } from 'react-hook-form';

const SearchFilter = () => {
  const { register } = useFormContext();

  const searchRegister = register('search');

  return (
    <div className='search-filter'>
      <FormControl
        fullWidth
        size='small'
        variant='outlined'
      >
        <InputLabel htmlFor='search-filter-input'>Search</InputLabel>
        <OutlinedInput
          {...searchRegister}
          onChange={debounce(searchRegister.onChange, 300)}
          id='search-filter-input'
          type='style'
          endAdornment={
            <InputAdornment position='end'>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          }
          label='Search'
        />
      </FormControl>
    </div>
  );
};

export default SearchFilter;
