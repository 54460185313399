import { marketCapRange } from "./market-cap-range";
import { metrics } from "./metrics";
import { volumeRange } from "./volume-range";

// Prepare default metrics in form object
const selectedMetrics = metrics
  .filter((metric) => metric.default === true)
  .map((metric) => metric.id);

selectedMetrics.sort();

export const defaultValues: any = {
  timeframe: 'week',
  metric: selectedMetrics,
  maxEntries: 100,
  sp500: false,
  nasdaq: false,
  nyse: false,
  presets: -1,
  sector: [
    "ENERGY & TRANSPORTATION",
    "FINANCE",
    "LIFE SCIENCES",
    "MANUFACTURING",
    "REAL ESTATE & CONSTRUCTION",
    "TECHNOLOGY",
    "TRADE & SERVICES"
],
  industry: [],
  volume: [0, volumeRange.length - 1],
  marketCap: [0, marketCapRange.length - 1],
  asc: false,
  search: '',
  minPrice: 0,
  maxPrice: 0,
  sortColumn: 'marketCap'
};
