import '../style/error-page.scss';

import monkeyTyping from '../assets/monkey-typing.gif';

import { useEffect } from 'react';

import BackButton from '../components/back-button';

const ErrorPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => window.location.reload(), 60000);

    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <div className='detail-page'>
      <div className='detail-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='error-page__container'>
        <img src={monkeyTyping} className='error-page__image' alt="Monkey"/>
        <h1 className='error-page__title mt-4 mb-2'>Site Maintenance</h1>
        <p className='error-page__text mb-8'>
          The site is currently currently being updated. We'll be back in a few minutes.
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
