import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const IndexFilter = ({ stonks }) => {
  const { register, getValues } = useFormContext();

  const values = getValues();

  return (
    <div className='index-filter'>
      <div className='flex flex-col justify-start flex-wrap'>
        <div className='inline-flex'>
          <FormControlLabel
            className='inline-flex'
            control={
              <Checkbox
                {...register('nasdaq')}
                checked={values.nasdaq}
                size='small'
              />
            }
            label={'NASDAQ'}
          />
        </div>
        <div className='inline-flex'>
          <FormControlLabel
            className='inline-flex'
            control={
              <Checkbox
                {...register('nyse')}
                checked={values.nyse}
                size='small'
              />
            }
            label={'NYSE'}
          />
        </div>
        <div className='inline-flex'>
          <FormControlLabel
            className='inline-flex'
            control={
              <Checkbox
                {...register('sp500')}
                checked={values.sp500}
                size='small'
              />
            }
            label={'S&P500'}
          />
        </div>
      </div>
    </div>
  );
};

export default IndexFilter;
