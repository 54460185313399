import { useMemo } from 'react';
import { useMainController } from '../hooks/use-main-controller';
import '../style/leaderboard-page.scss';

import { Link, useRouteMatch } from 'react-router-dom';
import BackButton from '../components/back-button';
import { usePromise } from '../hooks/use-promise';

const LeaderboardPage = () => {
  const match = useRouteMatch<{ metric: string }>();
  const metric = match.params.metric;
console.log('metric', metric);
  const mainController = useMainController();
  const leaderboardPromise = useMemo(() => mainController.loadLeaderboard(metric), [mainController, metric]);
  const leaderboard = usePromise({
    promise: leaderboardPromise,
    deps: [metric],
  });

  console.log('leaderboard', leaderboard);
  return (
    <div className='leaderboard-page'>
      <div className='leaderboard-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='leaderboard-page__container'>
      <div className='leaderboard-page__title'>Leaderboard for {metric}</div>
      <div className='leaderboard-page__profile'>
      </div>
        { leaderboard !== null ?
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>username</th>
                <th><Link to={`/tracker/leaderboard/count`}>count</Link></th>
                <th><Link to={`/tracker/leaderboard/dayRatio`}>dayRatio</Link></th>
                <th><Link to={`/tracker/leaderboard/dayWins`}>dayWins</Link></th>
                <th><Link to={`/tracker/leaderboard/dayWinLoss`}>dayWinLoss</Link></th>
                <th><Link to={`/tracker/leaderboard/dayQuality`}>dayQuality</Link></th>
                <th><Link to={`/tracker/leaderboard/weekRatio`}>weekRatio</Link></th>
                <th><Link to={`/tracker/leaderboard/weekWins`}>weekWins</Link></th>
                <th><Link to={`/tracker/leaderboard/weekWinLoss`}>weekWinLoss</Link></th>
                <th><Link to={`/tracker/leaderboard/weekQuality`}>weekQuality</Link></th>
                <th>updatedAt</th>
              </tr>
            </thead>
            { leaderboard.map( (tracker, index) => 
              <tr>
                <td>
                  {index + 1}
                </td>
                <td>
                  <Link
                    to={`/tracker/user/${tracker.username}`}
                  >
                    {tracker.username}
                  </Link>
                </td>
                <td>{tracker.count}</td>
                <td>{tracker.dayRatio}</td>
                <td>{tracker.dayWins}</td>
                <td>{tracker.dayWinLoss}</td>
                <td>{tracker.dayQuality}</td>
                <td>{tracker.weekRatio}</td>
                <td>{tracker.weekWins}</td>
                <td>{tracker.weekWinLoss}</td>
                <td>{tracker.weekQuality}</td>
                <td>{tracker.updatedAt}</td>
              </tr>
            ) }
          </table>
          : 'Loading' }
      </div>
    </div>
  );
};

export default LeaderboardPage;
