import '../style/side-scroller.scss';

import { useEffect, useRef } from 'react';

import { useWindowSize } from '../hooks/use-window-size';

export const SideScroller = ({ children }) => {
  const ref = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const windowSize = useWindowSize();

  useEffect(() => {
    // Kinda ghetto but idgaf
    // TODO: Make this less ghetto
    const appDom = document.querySelector('#root');
    const appDimensions = appDom.getBoundingClientRect();

    contentRef.current.style.left = ref.current.offsetLeft + 'px';
    contentRef.current.style.width = appDimensions.width - 2 * ref.current.offsetLeft + 'px';
  }, [windowSize])

  return <div ref={ref} className='side-scroller'>
    <div ref={contentRef} className='side-scroller__content'>
      {children}
    </div>
  </div>;
}
