import { Link } from 'react-router-dom';

import { Stonk } from "../../model/stonk";
import SortIcon from "../table/sort-icon";

class TickerAdapter {
  selector: (stonk: Stonk) => any;
  constructor(selector: (stonk: Stonk) => any) {
    this.selector = selector;
  }

  getTableHeader(title, sortStonks, sortColumn: boolean, asc: boolean) {
    return <p className='cursor-pointer' onClick={sortStonks}>
      {title} <SortIcon sortColumn={sortColumn} asc={asc} />
    </p>;
  }

  getView(stonk: Stonk) {
    const value = this.selector(stonk);

    return <div className='text-center stonk-table__sticky link'>
      <Link to={stonk.symbol}>{value}</Link>
    </div>;
  }
}

export default TickerAdapter;
