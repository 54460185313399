import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';

const BackButton = () => {
  const history = useHistory();

  const handleBack = () => {
    if (history.length > 1) {
      return history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <div className='inline-flex'>
      <IconButton
        onClick={handleBack}
        color='primary'
        aria-label='Back'
        size='small'
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </IconButton>
    </div>
  );
};

export default BackButton;
