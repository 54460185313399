import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import MainController from '../controller/main-controller';
import { MainControllerProvider } from './hooks/use-main-controller';
import App from './app';
import { Theme } from './theme';

interface RootProps {
  mainController: MainController;
  store: Store;
}

export const Root = (props: RootProps) => {
  return (
    <>
      <div>
        <MainControllerProvider value={props.mainController}>
          <Provider store={props.store}>
            <Theme>
              <App />
            </Theme>
          </Provider>
        </MainControllerProvider>
      </div>
    </>
  );
};

export default Root;
