import * as ActionTypes from './action-types';
import { Stonk } from './stonk';
import { User } from './user';

export interface AppState {
  error: Error;
  loadProgress: number;
  stonks: Stonk[];
  user: User;
}

export const initialState: AppState = {
  error: null,
  loadProgress: null,
  stonks: null,
  user: null,
};

export const resetStateKeys = ['error', 'loadProgress', 'stonks', 'user'];

export const persistActions = {
  [ActionTypes.USER_DID_CHANGE]: 'user'
};
