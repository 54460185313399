import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const PriceFilter = () => {
  const { register } = useFormContext();

  return (
    <div className='price-filter'>
      <p>Price</p>
      <div className='flex flex-wrap w-full my-4 h-32'>
        <div className='w-full md:w-48 flex items-center m-1'>
          <div className='flex items-center w-full md:w-48'>
            <FormControl size='small' fullWidth>
              <InputLabel htmlFor='price-filter-min'>Min Price</InputLabel>
              <OutlinedInput
                id='price-filter-min'
                type='number'
                {...register('minPrice')}
                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                label='Min Price'
              />
            </FormControl>
          </div>
        </div>
        <div className='w-full md:w-48 flex items-center m-1'>
          <div className='flex items-center w-full md:w-48'>
            <FormControl size='small' fullWidth>
              <InputLabel htmlFor='price-filter-max'>Max Price</InputLabel>
              <OutlinedInput
                id='price-filter-max'
                type='number'
                {...register('maxPrice')}
                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                label='Max Price'
              />
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PriceFilter;
