import { useEffect, useState } from 'react';

export function usePromise({
  promise,
  onError = console.error,
  initialValue = null,
  deps = [],
}): any {
  const [data, setData] = useState(initialValue);

  useEffect(() => {
    promise.then(setData).catch(onError);
  }, deps);

  return data;
}
