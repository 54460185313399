import { connect } from "react-redux";
import { Route } from "react-router-dom";

import { AppState } from "../../model/app-state";
import { getError, getStonks } from "../../model/selector/selectors";
import DetailPage from "../pages/detail-page";
import ErrorPage from "../pages/error-page";
import FilterPage from "../pages/filter-page";

export const StonkRoutes = ({ error, stonks }) => {
  if (stonks !== null) {
    stonks = stonks.filter(stonk => {
      return (
        stonk.overview !== null && 
        stonk.symbol !== null && 
        stonk.last.close !== null && 
        stonk.month.close !== null && 
        stonk.twoMonths.close !== null && 
        stonk.twoWeeks.close !== null && 
        stonk.week.close !== null
      );
    });
  }

  return error !== null ? <ErrorPage /> :
    <>
      <Route
        exact
        path='/'
      >
        <FilterPage stonks={stonks} />
      </Route>
      <Route path='/:symbol'>
        <DetailPage stonks={stonks} />
      </Route>
    </>
}

export default connect(() => {
  return (state: AppState) => {
    return {
      error: getError(state),
      stonks: getStonks(state),
    };
  };
})(StonkRoutes);
