import { Action } from 'redux';

import * as ActionTypes from '../action-types';
import { Stonk } from '../stonk';
import { User } from '../user';

export interface SetAction extends Action {
  payload: unknown;
}

export function reset(): Action {
  return {
    type: ActionTypes.RESET_APPLICATION,
  };
}

export function setError(state: Error): SetAction {
  return {
    type: ActionTypes.ERROR_DID_CHANGE,
    payload: state,
  };
}

export function setLoadProgress(state: number): SetAction {
  return {
    type: ActionTypes.LOAD_PROGRESS_DID_CHANGE,
    payload: state,
  };
}

export function setStonks(state: Stonk[]): SetAction {
  return {
    type: ActionTypes.STONKS_DID_CHANGE,
    payload: state,
  };
}

export function setUser(state: User): SetAction {
  return {
    type: ActionTypes.USER_DID_CHANGE,
    payload: state,
  };
}
