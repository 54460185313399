import { Store } from 'redux';
import { setUser } from '../model/action/actions';
import { getUser } from '../model/selector/selectors';
import { UserData } from '../model/user';
import { ApiService } from '../service/api-service';

export class AuthController {
  private store: Store;
  private apiService: ApiService;

  constructor(store: Store, apiService: ApiService) {
    this.store = store;
    this.apiService = apiService;
  }

  changePassword(password: string, newPassword: string) {
    return this.apiService.changePassword(password, newPassword).then((response) => {
      this.store.dispatch(setUser(response.data));
      return { message: 'Password successfully changed' };
    });
  }

  loadUser() {
    const state = this.store.getState();
    const user = getUser(state);

    if (user !== null) {
      return this.apiService.getUser().then((response) => {
        this.store.dispatch(setUser({ ...user, ...response.data }));
      }).catch(() => {
        this.store.dispatch(setUser(null));
      });
    }
  }

  login(email: string, password: string) {
    return this.apiService.login(email, password).then((response) => {
      this.store.dispatch(setUser(response.data));
    });
  }

  loginWithToken(accessToken: string) {
    this.store.dispatch(setUser({
      accessToken,
      email: undefined,
      id: undefined,
      roles: [],
      displayName: undefined,
      userData: undefined,
      bookmarks: []
    }));

    return this.loadUser();
  }

  logout() {
    this.store.dispatch(setUser(null));
    this.apiService.logout();
  }

  signUp(email: string, password: string, userData: UserData) {
    return this.apiService
      .signUp(email, password, userData)
      .then(() => {
        // Auto login
        return this.login(email, password);
      });
  }
}
