import { Stonk } from '../../model/stonk';
import { roundPrice } from '../../utils/math';
import RatioAverage from '../components/ratio-moving-average';

class PriceRangeAverageAdapter {
  constructor(
    private lowSelector: (stonk: Stonk) => any,
    private highSelector: (stonk: Stonk) => any,
    private currentSelector: (stonk: Stonk) => any,
    private midSelector: (stonk: Stonk) => any,
  ) {
  }

  getTableHeader(title, sortStonks) {
    return <p>{title}</p>;
  }

  getView(stonk: Stonk) {
    const lowValue = this.lowSelector(stonk);
    const highValue = this.highSelector(stonk);
    const currentValue = this.currentSelector(stonk);
    const midValue = this.midSelector(stonk);

    const roundedLow = roundPrice(lowValue);
    const roundedHigh = roundPrice(highValue);
    const roundedCurrent = roundPrice(currentValue);

    return <div className='flex w-full'>
      <RatioAverage min={roundedLow} max={roundedHigh} current={roundedCurrent} mid={midValue} />
    </div>;
  }
}

export default PriceRangeAverageAdapter;
