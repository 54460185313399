import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const MaxEntries = () => {
  const { control } = useFormContext();

  return (
    <div className='max-entries'>
      <FormControl fullWidth>
        <InputLabel id='maxEntries' size='small'>Max Entries</InputLabel>
        <Controller
          name='maxEntries'
          control={control}
          render={({ field }) => <Select
            {...field}
            labelId='maxEntries'
            fullWidth
            size='small'
            label='Max Entries'
          >
            <MenuItem value={100}>100 Results</MenuItem>
            <MenuItem value={500}>500 Results</MenuItem>
            <MenuItem value={1000}>1000 Results</MenuItem>
            <MenuItem value={0}>Show All</MenuItem>
          </Select>}
        />
      </FormControl>
    </div>
  )
};

export default MaxEntries;
