export const marketCapRange = [
  {
    value: 0,
    label: '<50M',
  },
  {
    value: 50000000,
    label: '50M',
  },
  {
    value: 300000000,
    label: '300M',
  },
  {
    value: 2000000000,
    label: '2B',
  },
  {
    value: 10000000000,
    label: '10B',
  },
  {
    value: 50000000000,
    label: '50B',
  },
  {
    value: 100000000000,
    label: '100B',
  },
  {
    value: 2000000000000,
    label: '2T',
  },
  {
    value: Number.MAX_SAFE_INTEGER,
    label: '>2T',
  }
];