import '../style/sign-up-page.scss';

import { useHistory } from 'react-router-dom';
import { UserData } from '../../model/user';
import BackButton from '../components/back-button';
import { SignUpForm } from '../forms/sign-up-form';
import { useMainController } from '../hooks/use-main-controller';

const SignUpPage = () => {
  const mainController = useMainController();
  const history = useHistory();

  const signUp = (data) => {
    return mainController.getAuthController().signUp(data.email, data.password, data).then(() => {
      // Redirect to landing page after successful login
      history.push('/');
    });
  };

  return (
    <div className='sign-up-page'>
      <div className='sign-up-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='sign-up-page__container p-8 text-center'>
        <h1 className='sign-up-page__title mb-8'>Sign Up</h1>
        <div className='sign-up-page__form'>
          <SignUpForm signUp={signUp} />
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
