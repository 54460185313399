export interface Transport {
  error(message?: unknown, ...optionalParams: unknown[]): void;
  log(message?: unknown, ...optionalParams: unknown[]): void;
  warn(message?: unknown, ...optionalParams: unknown[]): void;
}

class Logger {
  static debugMode = false;
  private tag: string;
  private transport: Transport = console;

  constructor(tag: string) {
    this.tag = tag;
  }

  dispose(): void {
    this.tag = null;
  }

  error(...args: unknown[]): void {
    this.writeTransport('error', args);
  }

  log(...args: unknown[]): void {
    this.writeTransport('log', args);
  }

  setTransport(transport: Transport): void {
    this.transport = transport;
  }

  warn(...args: unknown[]): void {
    this.writeTransport('warn', args);
  }

  writeTransport(level: 'error' | 'log' | 'warn', args: unknown[]): void {
    if (Logger.debugMode === true) {
      this.transport[level].apply(this.transport, [`[${this.tag}]`, ...args]);
    }
  }

  /**
   * Toggle logger functionality.
   * By default all logging is disabled in the project.
   * You should explicitly enable logger if you are interested in the logging messages.
   *
   * @param {boolean} state enable flag
   */
  static setDebugMode(state: boolean): void {
    Logger.debugMode = state;
  }
}

export { Logger };
