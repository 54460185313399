import '../style/login-page.scss';

import { Link, useHistory } from 'react-router-dom';
import BackButton from '../components/back-button';
import { LoginForm } from '../forms/login-form';
import { useMainController } from '../hooks/use-main-controller';
import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : window.origin;

const LoginPage = () => {
  const mainController = useMainController();
  const history = useHistory();
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleEvent = async (event) => {
      if (event.origin === apiUrl) {
        const accessToken = event.data.accessToken;
        const error = event.data.error;
        if (accessToken !== undefined) {
          return mainController.getAuthController().loginWithToken(accessToken).then(() => {
            // Redirect to landing page after successful login
            history.push('/');
          });
        }
        if (error !== undefined) {
          setError(error);
          return mainController.getAuthController().logout();
        }
      }
    };

    window.addEventListener('message', handleEvent);

    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [history, mainController]);

  const login = (email: string, password: string) => {
    return mainController.getAuthController().login(email, password).then(() => {
      // Redirect to landing page after successful login
      history.push('/');
    });
  };

  const handleGoogleLogin = () => {
    const url = `${apiUrl}/api/auth/google`;
    const windowParams = 'width=500,height=600,resizable,scrollbars=yes,status=1';
    window.open(url, '_blank', windowParams);
  };

  return (
    <div className='login-page'>
      <div className='login-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='login-page__container p-8 text-center'>
        <h1 className='login-page__title mb-8'>Login</h1>
        <div className='login-page__form'>
          <LoginForm login={login} />
          {error !== null ? (
            <div className='my-4'>
              <Typography color='error'>{error}</Typography>
            </div>
          ) : null}
          <div className='my-4' style={{ color: '#dddddd' }}>
            <Button fullWidth variant='outlined' color={'inherit'} onClick={handleGoogleLogin} startIcon={<FontAwesomeIcon icon={faGoogle} />}>Sign in with Google</Button>
          </div>
        </div>
        <div className='login-page__actions mt-6'>
          <Link to='/sign-up'>
            <p>Sign up</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
