export const volumeRange = [
  {
    value: 0,
    label: '<50K',
  },
  {
    value: 50000,
    label: '50K',
  },
  {
    value: 100000,
    label: '100K',
  },
  {
    value: 500000,
    label: '500K',
  },
  {
    value: 750000,
    label: '750K',
  },
  {
    value: 1000000,
    label: '1M',
  },
  {
    value: 2000000,
    label: '2M',
  },
  {
    value: 5000000,
    label: '5M',
  },
  {
    value: 10000000,
    label: '10M',
  },
  {
    value: 20000000,
    label: '20M',
  },
  {
    value: 50000000,
    label: '50M',
  },
  {
    value: Number.MAX_SAFE_INTEGER,
    label: '>50M',
  }
];
