import '../style/disclosures-page.scss';

import BackButton from '../components/back-button';

const DisclosuresPage = () => {
  return (
    <div className='disclosures-page mx-6'>
      <div className='disclosures-page__back-button mt-4'>
        <BackButton />
      </div>
      <div className='disclosures-page__container'>
        <p className='disclosures-page__title mb-2'>WEBSITE DISCLAIMER</p>
        <p className='disclosures-page__text mb-8'>
          The information provided by Stonk Finder ("we." "us." or "our") on <a href='http://www.stonkfinder.com'>http://www.stonkfinder.com</a> (the "Site") and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
        </p>

        <p className='disclosures-page__title mb-2'>EXTERNAL LINKS DISCLAIMER</p>
        <p className='disclosures-page__text mb-8'>
          The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES
        </p>

        <p className='disclosures-page__title mb-2'>PROFESSIONAL DISCLAIMER</p>
        <p className='disclosures-page__text mb-8'>
          The Site cannot and does not contain finance advice. The finance information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of finance advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE SITE OR OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
        </p>

        <p className='disclosures-page__title mb-2'>AFFILIATES DISCLAIMER</p>
        <p className='disclosures-page__text mb-8'>
          The Site and our mobile application may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links
          Our affiliates include the following:
          <p className='mt-4'>Google</p>
          <p className='mt-4'>Stocktwits</p>
          <p className='mt-4'>TradingView</p>
          <p className='mt-4'>Yahoo</p>
        </p>
      </div>
    </div>
  );
};

export default DisclosuresPage;
