import '../style/filter-page.scss';

import { connect } from 'react-redux';
import { AppState } from '../../model/app-state';
import { isUserBeta } from '../../model/selector/is-user-beta';

const BetaGuard = ({ beta, children }) => {
  return beta ? children: null;
};

export default connect(() => {
  return (state: AppState) => {
    return {
      beta: isUserBeta()(state),
    };
  };
})(BetaGuard);
