import '../style/loader.scss';

import { LinearProgress } from '@mui/material';
import { connect } from 'react-redux';

import { AppState } from '../../model/app-state';
import { getLoadProgress } from '../../model/selector/selectors';

export const Loader = ({ children, loading, progress }) => {
  return loading === true ? (
    <div className='loader my-2 w-full'>
      <div className='loader__progress-bar w-full p-4'>
        <p className='loader__label text-center'>Loading</p>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </div>
  ) : children;
};

export default connect(() => {
  return (state: AppState) => {
    return {
      progress: getLoadProgress(state),
    };
  };
})(Loader);
