import { Avatar, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { MouseEventHandler, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { AppState } from "../../model/app-state";
import { getUser } from "../../model/selector/selectors";
import { useMainController } from "../hooks/use-main-controller";

export const UserActions = (props) => {
  const mainController = useMainController();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleUserMenu: MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = props.user;

  return user !== null ? (
    <>
      <IconButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleUserMenu}
        color='inherit'
      >
        <Avatar sx={{ bgcolor: '#0CC6E2' }}>
          <p className='text-base'>
            {user.userData?.firstName[0]}
            {user.userData?.lastName[0]}
          </p>
        </Avatar>
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to='/change-password'
          onClick={() => handleClose()}
        >
          Change Password
        </MenuItem>
        <MenuItem
          component={Link}
          to='/'
          onClick={() => {
            handleClose();
            mainController.getAuthController().logout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  ) : (
    <Button color='inherit' component={Link} to='/login'>
      Login
    </Button>
  );
}

export default connect(() => {
  return (state: AppState) => {
    return {
      user: getUser(state),
    };
  };
})(UserActions);
