import { Store } from 'redux';
import pako from 'pako';

import { setLoadProgress } from '../model/action/actions';
import { getUser } from '../model/selector/selectors';
import { Stonk } from '../model/stonk';
import { UserData } from '../model/user';

export class ApiService {
  private baseUrl: string;

  constructor(private store: Store) {
    this.baseUrl = process.env.NODE_ENV === 'development'
      ? `http://localhost:3000/api`
      : `/api`;
  }

  addBookmark(symbol: string) {
    const apiUrl = `${this.baseUrl}/bookmark/${symbol}`;
    return this.post(apiUrl);
  }

  changePassword(password: string, newPassword: string) {
    const apiUrl = `${this.baseUrl}/auth/change-password`;

    return this.post(apiUrl, {
      password, newPassword
    });
  }

  delete(endpoint) {
    return this.request(endpoint, {
      method: 'DELETE',
    });
  }

  get(endpoint) {
    return this.request(endpoint, {
      method: 'GET',
    });
  }

  getAuthHeaders() {
    const authHeaders = {};

    const state = this.store.getState();
    const user = getUser(state);

    if (user !== null) {
      authHeaders['Authorization'] = `Bearer ${user.accessToken}`;
    }

    return authHeaders;
  }

  getBookmarks() {
    const apiUrl = `${this.baseUrl}/bookmark`;
    return this.get(apiUrl);
  }

  getUser() {
    const apiUrl = `${this.baseUrl}/auth`;
    return this.get(apiUrl);
  }

  loadLeaderboard(metric: string) {
    return this.get(`${this.baseUrl}/stocktwits/leaderboard/${metric}`);
  }

  loadRating(user: string) {
    return this.get(`${this.baseUrl}/stocktwits/mmr/${user}`);
  }

  loadStonks() {
    const api = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/data/stonks' : '/data/stonks';
    this.store.dispatch(setLoadProgress(0));

    return fetch(api)
      .then(response => {
        const contentLength = response.headers.get('Content-Length');
        const totalBytes = parseInt(contentLength, 10);
        let loadedBytes = 0;

        const reader = response.body.getReader();

        return new ReadableStream({
          start: (controller) => {
            const readChunk = () => {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }

                loadedBytes += value.length;
                const progress = Math.floor((loadedBytes / totalBytes) * 100);

                // Update the progress bar here
                this.store.dispatch(setLoadProgress(progress));

                controller.enqueue(value);
                readChunk();
              }).catch(error => {
                controller.error(error);
              });
            }

            readChunk();
          }
        });
      })
      .then(stream => new Response(stream))
      .then(response => response.arrayBuffer())
      .then(buffer => pako.ungzip(buffer, { to: 'string' }))
      .then(data => {
        // Do something with the decompressed data
        let lineSeparated = data.split('\n');
        lineSeparated = lineSeparated.filter(line => line.length > 0);
        const stonks: Stonk[] = lineSeparated.map((line, index) => {
          try {
            return JSON.parse(line)
          } catch (e) {
            console.error(e);
            return null;
          }
        });

        return stonks.filter(stonk => stonk !== null);
      });
  }

  loadTwits(symbol: string) {
    return this.get(`${this.baseUrl}/stocktwits/${symbol}`);
  }

  login(email: string, password: string) {
    const apiUrl = `${this.baseUrl}/auth/login`;

    return this.post(apiUrl, {
      email, password
    });
  }

  logout() {
    const apiUrl = `${this.baseUrl}/auth/logout`;
    
    return this.request(apiUrl, {
      method: 'GET',
      credentials: 'include',
    });
  }

  post(endpoint, body = {}) {
    return this.request(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  removeBookmark(symbol: string) {
    const apiUrl = `${this.baseUrl}/bookmark/${symbol}`;
    return this.delete(apiUrl);
  }

  request(endpoint, options) {
    return fetch(endpoint, {
      headers: {
        ...this.getAuthHeaders(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    }).then((response) => {
      const { status, statusText } = response;
      const result = {
        data: null,
        status,
        statusText,
        success: status >= 200 && status < 300,
      };

      return response
        .json()
        .then(
          (parseResult) => {
            result.data = parseResult;

            // this.responseHandler !== undefined && this.responseHandler(result);

            return result;
          },
          (error) => {
            console.warn('Can not parse a response body, error: ' + error);
            return result;
          },
        )
        .then((result) => {
          if (result.success === false) {
            const data = result.data;

            if (data.message !== undefined) {
              return Promise.reject(new Error(data.message));
            } else {
              return Promise.reject(new Error(data));
            }
          } else {
            return result;
          }
        });
    });
  }

  signUp(email: string, password: string, userData: UserData) {
    const apiUrl = `${this.baseUrl}/auth/sign-up`;
    return this.post(apiUrl, {
      email, password, ...userData
    });
  }
}
