import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { formatText } from '../../utils/format-text';

const SectorFilter = ({ stonks }) => {
  const { register, getValues } = useFormContext();
  let sectors = [];

  if (stonks !== null) {
    sectors = Array.from(
      new Set<any>(
        stonks
          .filter((stonk) => stonk.overview !== undefined && stonk.overview.Sector !== undefined)
          .map((stonk) => stonk.overview.Sector),
      ),
    );
    sectors.sort();
  }

  const values = getValues();
  const currentSectors = values.sector;

  return (
    <div className='sector-filter'>
      <div className='flex flex-col justify-start flex-wrap'>
        {sectors.map((sector) => (
          <div
            key={sector}
            className='inline-flex'
          >
            <FormControlLabel
              className='inline-flex'
              control={
                <Checkbox
                  {...register('sector')}
                  value={sector}
                  checked={currentSectors.includes(sector)}
                  size='small'
                />
              }
              label={formatText(sector)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectorFilter;
