import Fuse from 'fuse.js';

import { marketCapRange } from '../model/market-cap-range';
import { Stonk } from '../model/stonk';
import { volumeRange } from '../model/volume-range';

export const filterStonks = (stonks: Stonk[], filters) => {
  stonks = stonks.filter((stonk) => {
    //Filter for undefined values
    if (!stonk.overview || !stonk.overview.Exchange) {
      return false;
    }
    //Filter for S&P 500
    if (filters.sp500 && !stonk.sp500) {
      return false;
    }
    //Filter for Nasdaq
    if (filters.nasdaq && stonk.overview.Exchange !== 'NASDAQ') {
      return false;
    }
    //Filter for NYSE
    if (filters.nyse && stonk.overview.Exchange !== 'NYSE') {
      return false;
    }
    //Filter for Dow Jones
    if (filters.dowJones && !stonk.dowJones) {
      return false;
    }
    //Filter for minimum price
    if (filters.minPrice > 0 && stonk.last.close < filters.minPrice) {
      return false;
    }
    //Filter for maximum price
    if (filters.maxPrice > 0 && stonk.last.close > filters.maxPrice) {
      return false;
    }
    //Filter for market capitalization
    if (
      stonk.overview.MarketCapitalization > marketCapRange[filters.marketCap[1]].value ||
      stonk.overview.MarketCapitalization < marketCapRange[filters.marketCap[0]].value
    ) {
      return false;
    }

    //Filter for volume
    if (
      stonk.last.volume > volumeRange[filters.volume[1]].value ||
      stonk.last.volume < volumeRange[filters.volume[0]].value
    ) {
      return false;
    }

    //Filter for sector
    if (filters.sector !== undefined && filters.sector.length > 0 && !filters.sector.includes(stonk.overview.Sector)) {
      return false;
    }
    //Filter for industry
    if (
      filters.industry !== undefined &&
      filters.industry.length > 0 &&
      !filters.industry.includes(stonk.overview.Industry)
    ) {
      return false;
    }
    return true;
  });

  //Search filter
  if (filters.search) {
    const fuse = new Fuse(stonks, {
      keys: ['overview.Symbol', 'overview.Name'],
      threshold: 0.37,
    });
    const results = fuse.search(filters.search);
    stonks = results.map((result) => result.item);
  }

  return stonks;
};
