import { format } from "date-fns";
import ActionAdapter from "../view/adapter/action-adapter";
import NumberAdapter from "../view/adapter/number-adapter";
import NumberCompareAdapter from "../view/adapter/number-compare-adapter";
import PercentAdapter from "../view/adapter/percent-adapter";
import PriceAdapter from "../view/adapter/price-adapter";
import PriceCompareAdapter from "../view/adapter/price-compare-adapter";
import PriceDeltaAdapter from "../view/adapter/price-delta-adapter";
import PriceRangeAverageAdapter from '../view/adapter/price-range-moving-average-adapter';
import SectorAdapter from "../view/adapter/sector-adapter";
import TextAdapter from "../view/adapter/text-adapter";
import TickerAdapter from "../view/adapter/ticker-adapter";
import { Stonk } from "./stonk";

const createOverviewComparator = (key) => (a, b) => {
  if (a.overview === undefined || a.overview[key] === undefined) {
    return -1;
  }
  if (b.overview === undefined || a.overview[key] === undefined) {
    return 1;
  }
  return a.overview[key] - b.overview[key];
}

const generateOverviewMetric = (metric, text, description = undefined) => ({
  id: metric,
  text,
  description,
  adapter: new NumberAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview[metric]),
  sort: createOverviewComparator(metric),
});

const createRatioComparator = (selector: (stonk: Stonk) => number, referenceSelector: (stonk: Stonk) => number) => (a: Stonk, b: Stonk) => {
  const aMetric = selector(a);
  const bMetric = selector(b);
  const referenceA = referenceSelector(a);
  const referenceB = referenceSelector(b);

  if (aMetric !== undefined && bMetric !== undefined && aMetric !== null && bMetric !== null) {
    const aRatio = referenceA / aMetric;
    const bRatio = referenceB / bMetric;

    return aRatio - bRatio;
  } else {
    if (aMetric !== null && bMetric === null) {
      return 1;
    } else if (aMetric === null && bMetric !== null) {
      return -1;
    } else {
      return 0;
    }
  }
};

export interface Metric {
  id: string;
  text: string;
  description?: string;
  default?: boolean;
  locked?: boolean;
  adapter: any;
  sort?: (a: Stonk, b: Stonk) => number;
}

export const metrics: Metric[] = [
  {
    id: 'ticker',
    text: 'Ticker',
    locked: true,
    adapter: new TickerAdapter((stonk: Stonk) => stonk.symbol),
    sort: (a, b) => a.symbol.localeCompare(b.symbol),
  },
  {
    id: 'name',
    text: 'Name',
    default: true,
    adapter: new TextAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.Name),
    sort: (a, b) => {
      if (a.overview === undefined || a.overview.Name === undefined) {
        return -1;
      }
      if (b.overview === undefined || b.overview.Name === undefined) {
        return 1;
      }
      return a.overview.Name.localeCompare(b.overview.Name)
    },
  },
  {
    id: 'updatedAt',
    text: 'Update Time',
    adapter: new TextAdapter(
      (stonk: Stonk) => format(new Date(stonk.last.date), 'MM/dd/yyyy p')
    ),
    sort: (a, b) => new Date(a.last.date).getDate() - new Date(b.last.date).getDate(),
  },
  {
    id: 'sector',
    text: 'Sector',
    default: true,
    adapter: new SectorAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.Sector),
    sort: (a, b) => {
      if (a.overview === undefined || a.overview.Sector === undefined) {
        return -1;
      }
      if (b.overview === undefined || a.overview.Sector === undefined) {
        return 1;
      }
      return a.overview.Sector.localeCompare(b.overview.Sector)
    },
  },
  {
    id: 'industry',
    text: 'Industry',
    adapter: new TextAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.Industry),
    sort: (a, b) => {
      if (a.overview === undefined || a.overview.Industry === undefined) {
        return -1;
      }
      if (b.overview === undefined || a.overview.Industry === undefined) {
        return 1;
      }
      return a.overview.Industry.localeCompare(b.overview.Industry)
    },
  },
  {
    id: 'exchange',
    text: 'Exchange',
    adapter: new TextAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.Exchange),
    sort: (a, b) => {
      if (a.overview === undefined || a.overview.Exchange === undefined) {
        return -1;
      }
      if (b.overview === undefined || a.overview.Exchange === undefined) {
        return 1;
      }
      return a.overview.Exchange.localeCompare(b.overview.Exchange)
    },
  },
  {
    id: 'assetType',
    text: 'Asset Type',
    adapter: new TextAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.AssetType),
    sort: (a, b) => {
      if (a.overview === undefined || a.overview.AssetType === undefined) {
        return -1;
      }
      if (b.overview === undefined || a.overview.AssetType === undefined) {
        return 1;
      }
      return a.overview.AssetType.localeCompare(b.overview.AssetType)
    },
  },
  {
    id: 'marketCap',
    text: 'Market Capitalization',
    default: true,
    adapter: new PriceAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.MarketCapitalization),
    sort: createOverviewComparator('MarketCapitalization'),
  },
  {
    id: 'delta',
    text: 'Week Delta Price',
    adapter: new PriceDeltaAdapter((stonk: Stonk) => stonk.week.close.delta * 100),
    sort: (a, b) => a.week.close.delta - b.week.close.delta,
  },
  {
    id: 'deltaRatio',
    text: 'Week Delta Percent',
    default: true,
    adapter: new PercentAdapter((stonk: Stonk) => stonk.week.close.deltaRatio * 100),
    sort: (a, b) => a.week.close.deltaRatio - b.week.close.deltaRatio,
  },
  {
    id: 'delta2Week',
    text: '2 Week Delta Price',
    adapter: new PriceDeltaAdapter((stonk: Stonk) => stonk.twoWeeks.close.delta * 100),
    sort: (a, b) => a.twoWeeks.close.delta - b.twoWeeks.close.delta,
  },
  {
    id: 'deltaRatio2Week',
    text: '2 Week Delta Percent',
    adapter: new PercentAdapter((stonk: Stonk) => stonk.twoWeeks.close.deltaRatio * 100),
    sort: (a, b) => a.twoWeeks.close.deltaRatio - b.twoWeeks.close.deltaRatio,
  },
  {
    id: 'deltaMonth',
    text: 'Month Delta Price',
    adapter: new PriceDeltaAdapter((stonk: Stonk) => stonk.month.close.delta * 100),
    sort: (a, b) => a.month.close.delta - b.month.close.delta,
  },
  {
    id: 'deltaRatioMonth',
    text: 'Month Delta Percent',
    adapter: new PercentAdapter((stonk: Stonk) => stonk.month.close.deltaRatio * 100),
    sort: (a, b) => a.month.close.deltaRatio - b.month.close.deltaRatio,
  },
  {
    id: 'delta2Month',
    text: '2 Month Delta Price',
    adapter: new PriceDeltaAdapter((stonk: Stonk) => stonk.twoMonths.close.delta * 100),
    sort: (a, b) => a.twoMonths.close.delta - b.twoMonths.close.delta,
  },
  {
    id: 'deltaRatio2Month',
    text: '2 Month Delta Percent',
    adapter: new PercentAdapter((stonk: Stonk) => stonk.twoMonths.close.deltaRatio * 100),
    sort: (a, b) => a.twoMonths.close.deltaRatio - b.twoMonths.close.deltaRatio,
  },
  {
    id: 'weekAverage',
    default: true,
    text: 'Week Close Average',
    adapter: new PriceCompareAdapter(
      (stonk: Stonk) => stonk.week.close.average,
      (stonk: Stonk) => stonk.last.close
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.week.close.average, (stonk: Stonk) => stonk.last.close)
  },
  {
    id: 'monthAverage',
    text: 'Month Close Average',
    adapter: new PriceCompareAdapter(
      (stonk: Stonk) => stonk.month.close.average,
      (stonk: Stonk) => stonk.last.close
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.month.close.average, (stonk: Stonk) => stonk.last.close)
  },
  {
    id: 'twoMonthAverage',
    text: '2 Month Close Average',
    adapter: new PriceCompareAdapter(
      (stonk: Stonk) => stonk.twoMonths.close.average,
      (stonk: Stonk) => stonk.last.close
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.twoMonths.close.average, (stonk: Stonk) => stonk.last.close)
  },
  {
    id: '50DayAverage',
    text: '50 Day Average',
    adapter: new PriceCompareAdapter(
      (stonk: Stonk) => stonk.overview !== undefined ? stonk.overview["50DayMovingAverage"] : null,
      (stonk: Stonk) => stonk.last.close
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.overview['50DayMovingAverage'], (stonk: Stonk) => stonk.last.close)
  },
  {
    id: '200DayAverage',
    text: '200 Day Average',
    default: true,
    adapter: new PriceCompareAdapter(
      (stonk: Stonk) => stonk.overview !== undefined ? stonk.overview["200DayMovingAverage"] : null,
      (stonk: Stonk) => stonk.last.close
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.overview['200DayMovingAverage'], (stonk: Stonk) => stonk.last.close)
  },
  {
    id: 'analystTargetPrice',
    text: 'Analyst Target Price',
    adapter: new PriceCompareAdapter(
      (stonk: Stonk) => stonk.overview !== undefined ? stonk.overview["AnalystTargetPrice"] : null,
      (stonk: Stonk) => stonk.last.close
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.overview['AnalystTargetPrice'], (stonk: Stonk) => stonk.last.close)
  },
  {
    id: 'volume',
    text: 'Volume',
    adapter: new NumberAdapter((stonk: Stonk) => stonk.last.volume),
    sort: (a, b) => a.last.volume - b.last.volume,
  },
  {
    id: 'weekVolumeAverage',
    default: true,
    text: 'Week Volume Average',
    adapter: new NumberCompareAdapter(
      (stonk: Stonk) => stonk.week.volume.average,
      (stonk: Stonk) => stonk.last.volume
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.week.volume.average, (stonk: Stonk) => stonk.last.volume)
  },
  {
    id: 'monthVolumeAverage',
    text: 'Month Volume Average',
    adapter: new NumberCompareAdapter(
      (stonk: Stonk) => stonk.month.volume.average,
      (stonk: Stonk) => stonk.last.volume
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.month.volume.average, (stonk: Stonk) => stonk.last.volume)
  },
  {
    id: 'twoMonthVolumeAverage',
    text: '2 Month Volume Average',
    adapter: new NumberCompareAdapter(
      (stonk: Stonk) => stonk.twoMonths.volume.average,
      (stonk: Stonk) => stonk.last.volume
    ),
    sort: createRatioComparator((stonk: Stonk) => stonk.twoMonths.volume.average, (stonk: Stonk) => stonk.last.volume)
  },
  {
    id: 'weekRange',
    text: 'Week Range',
    default: true,
    description: 'The lowest and highest closing price of the week and the current close- compared against the average closing prices for the week.',
    adapter: new PriceRangeAverageAdapter(
      (stonk: Stonk) => stonk.week.close.low,
      (stonk: Stonk) => stonk.week.close.high,
      (stonk: Stonk) => stonk.last.close,
      (stonk: Stonk) => stonk.week.close.average,
    ),
  },
  {
    id: '2WeekRange',
    text: '2 Week Range',
    description: 'The lowest and highest closing price of over two weeks and the current close- compared against the average closing prices over two weeks.',
    adapter: new PriceRangeAverageAdapter(
      (stonk: Stonk) => stonk.twoWeeks.close.low,
      (stonk: Stonk) => stonk.twoWeeks.close.high,
      (stonk: Stonk) => stonk.last.close,
      (stonk: Stonk) => stonk.twoWeeks.close.average,
    ),
  },
  {
    id: '1MonthRange',
    text: '1 Month Range',
    description: 'The lowest and highest closing price of the month and the current close- compared against the average closing prices for the month.',
    adapter: new PriceRangeAverageAdapter(
      (stonk: Stonk) => stonk.month.close.low,
      (stonk: Stonk) => stonk.month.close.high,
      (stonk: Stonk) => stonk.last.close,
      (stonk: Stonk) => stonk.month.close.average,
    ),
  },
  {
    id: '2MonthRange',
    text: '2 Month Range',
    description: 'The lowest and highest closing price of over two months and the current close- compared against the average closing prices over two months.',
    adapter: new PriceRangeAverageAdapter(
      (stonk: Stonk) => stonk.twoMonths.close.low,
      (stonk: Stonk) => stonk.twoMonths.close.high,
      (stonk: Stonk) => stonk.last.close,
      (stonk: Stonk) => stonk.twoMonths.close.average,
    ),
  },
  {
    id: '52WeekRange',
    text: '52 Week Range',
    default: true,
    description: 'The lowest and highest closing price of over 52 weeks and the current close- compared against the 200 day moving average.',
    adapter: new PriceRangeAverageAdapter(
      (stonk: Stonk) => stonk.overview !== undefined && stonk.overview["52WeekLow"],
      (stonk: Stonk) => stonk.overview !== undefined && stonk.overview["52WeekHigh"],
      (stonk: Stonk) => stonk.last.close,
      (stonk: Stonk) => stonk.overview !== undefined && stonk.overview["200DayMovingAverage"],
    ),
  },
  {
    id: 'beta',
    text: 'Beta',
    adapter: new TextAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.Beta),
    sort: createOverviewComparator('Beta'),
  },
  {
    id: 'dividendPerShare',
    text: 'Dividend Per Share',
    adapter: new TextAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.DividendPerShare),
    sort: createOverviewComparator('DividendPerShare'),
  },
  {
    id: 'dividendYield',
    text: 'Dividend Yield',
    adapter: new TextAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.DividendYield),
    sort: createOverviewComparator('DividendYield'),
  },
  {
    id: 'eps',
    text: 'EPS',
    adapter: new PriceAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.EPS),
    sort: createOverviewComparator('EPS'),
  },
  {
    id: 'ebitda',
    text: 'EBITDA',
    adapter: new PriceAdapter((stonk: Stonk) => stonk.overview !== undefined && stonk.overview.EBITDA),
    sort: createOverviewComparator('EBITDA'),
  },
  // TODO: Define better rendering strategies for these metrics
  generateOverviewMetric('PERatio', 'PE Ratio'),
  generateOverviewMetric('PEGRatio', 'PEG Ratio'),
  generateOverviewMetric('BookValue', 'Book Value'),
  generateOverviewMetric('RevenuePerShareTTM', 'Revenue Per Share TTM'),
  generateOverviewMetric('ProfitMargin', 'Profit Margin'),
  generateOverviewMetric('OperatingMarginTTM', 'Operating Margin TTM'),
  generateOverviewMetric('ReturnOnAssetsTTM', 'Return On Assets TTM'),
  generateOverviewMetric('ReturnOnEquityTTM', 'Return On Equity TTM'),
  generateOverviewMetric('RevenueTTM', 'Revenue TTM'),
  generateOverviewMetric('GrossProfitTTM', 'GrossProfit TTM'),
  generateOverviewMetric('DilutedEPSTTM', 'Diluted EPS TTM'),
  generateOverviewMetric('QuarterlyEarningsGrowthYOY', 'Quarterly Earnings Growth YOY'),
  generateOverviewMetric('QuarterlyRevenueGrowthYOY', 'Quarterly Revenue Growth YOY'),
  generateOverviewMetric('TrailingPE', 'Trailing PE'),
  generateOverviewMetric('ForwardPE', 'Forward PE'),
  generateOverviewMetric('PriceToSalesRatioTTM', 'Price To Sales Ratio TTM'),
  generateOverviewMetric('PriceToBookRatio', 'Price To Book Ratio'),
  generateOverviewMetric('EVToRevenue', 'EV To Revenue'),
  generateOverviewMetric('EVToEBITDA', 'EV To EBITDA'),
  generateOverviewMetric('SharesOutstanding', 'Shares Outstanding'),
  {
    id: 'actionAdapter',
    text: 'Action',
    locked: true,
    adapter: new ActionAdapter()
  },

];
