import '../style/filter-page.scss';

import { Tab, Tabs, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultValues } from '../../model/default-values';
import { metrics } from '../../model/metrics';
import { objectToParams, parseQuery } from '../../utils/encode-params';
import { filterStonks } from '../../utils/filter';
import LastUpdate from '../components/last-update';
import Loader from '../widgets/loader';
import MaxEntries from '../components/max-entries';
import { SideScroller } from '../components/side-scroller';
import Filters from '../filters/filters';
import SearchFilter from '../filters/search-filter';
import StonkTable from '../table/stonk-table';
import { applyUserValues, removeDefaultValues } from '../../utils/apply-defaults';
import classNames from 'classnames';
import { Debounce } from '../components/debounce';
import { connect } from 'react-redux';
import { AppState } from '../../model/app-state';
import { getUser } from '../../model/selector/selectors';
import { Stonk } from '../../model/stonk';

const FilterPage = ({ stonks, user }) => {
  // Get query params from URL
  const history = useHistory();
  const [filterState, setFilterState] = useState(false);
  const [tabState, setTabState] = useState(0);
  const filterParams = parseQuery('filter');
  const userParams = applyUserValues(defaultValues, filterParams);

  // Form logic
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      ...userParams,
    },
  });
  const { watch, setValue } = methods;
  const watchAllFields = watch();

  // Change URL to include query params
  watchAllFields.metric.sort();
  const changeValues = removeDefaultValues(defaultValues, watchAllFields);
  const params = objectToParams('filter', changeValues);
  const paramString = params.toString();
  useEffect(() => {
    history.replace({
      pathname: window.location.pathname,
      search: paramString,
    });
  }, [history, paramString]);

  // Include locked metrics into form data
  const metricsList = [...watchAllFields.metric];
  metrics.filter((metric) => metric.locked === true).forEach((metric) => metricsList.push(metric.id));

  // Apply metric filters
  const filteredMetrics = metrics.filter((metric) => metricsList.includes(metric.id));

  // Apply stonk filters
  let filteredStonks: Stonk[] = null;

  if (stonks !== null) {
    filteredStonks = stonks;
  }
  
  if (filteredStonks !== null && user !== null && tabState === 1) {
    const bookmarks = user.bookmarks;
    filteredStonks = filteredStonks.filter(stock => bookmarks.includes(stock.symbol))
  }

  if (filteredStonks !== null) {
    filteredStonks = filterStonks(filteredStonks, watchAllFields);
  }

  // Sort Logic
  const { sortColumn, asc } = watchAllFields;
  const sortBy = (column) => {
    if (sortColumn === column) {
      setValue('asc', !asc, { shouldDirty: true });
    } else {
      setValue('sortColumn', column);
      setValue('asc', true, { shouldDirty: true });
    }
  };

  const classes = classNames(
    'filter-page',
    'p-2',
    'md:p-6',
    { 'filter-page--filter-open': filterState }
  );

  const onTabChange = (event, value) => {
    if (user !== null) {
      setTabState(value);
    } else if (value === 1) {
      history.push('/login');
    }
  };

  return (
    <div className={classes}>
      <FormProvider {...methods}>
        <SideScroller>
          <div className='filter-page__header'>
            <div className='flex flex-wrap justify-between items-center mb-10'>
              <div className='flex items-center justify-between w-full md:w-fit mb-2 md:mb-0'>
                <div className='mr-6'>
                  <h1 className='text-4xl'>Stonks</h1>
                  <LastUpdate stonks={filteredStonks} />
                </div>
                <MaxEntries />
              </div>
              <div className='w-full md:w-fit'>
                <SearchFilter />
              </div>
            </div>
            <div className='filter-page__tabs'>
              <Tabs value={tabState} onChange={onTabChange}>
                <Tab label="All Stonks" />
                <Tab label="My List" />
              </Tabs>
            </div>
            <div className='filter-page__sorting-options'>
              <ToggleButtonGroup
                value={filterState}
                onChange={(event, value) => setFilterState(value)}
                exclusive
                size='small'
              >
                <ToggleButton value={true}>
                  <FontAwesomeIcon icon={faFilter} className='mr-2' />
                  Filters
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </SideScroller>

        <Loader loading={stonks === null || stonks.length === 0}>
          <div className='filter-page__container'>
            {filterState && (
              <Filters
                stonks={stonks}
                close={() => setFilterState(false)}
              />
            )}
            <div className='filter-page__table'>
              <Debounce wait={50}
                props={{stonks: filteredStonks, metrics: filteredMetrics, sortBy, ...watchAllFields}}
              >
                {(props) => <StonkTable {...props}/>}
              </Debounce>
            </div>
          </div>
        </Loader>
      </FormProvider>
    </div>
  );
};

export default connect(() => {
  return (state: AppState) => {
    return {
      user: getUser(state),
    };
  };
})(FilterPage);
