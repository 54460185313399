import ReactDOM from 'react-dom/client';
import { Store } from 'redux';
import { setError, setStonks } from '../model/action/actions';

import { getInitialState, getNewStore } from '../model/store';
import { ApiService } from '../service/api-service';
import Root from '../view/root';
import { AuthController } from './auth-controller';
import { BookmarkController } from './bookmark-controller';

export default class MainController {
  private store: Store;
  private apiService: ApiService;
  private bookmarkController: BookmarkController;
  private authController: AuthController;

  constructor() {
    this.store = getNewStore(getInitialState());

    this.apiService = new ApiService(this.store);
    this.authController = new AuthController(this.store, this.apiService);
    this.bookmarkController = new BookmarkController(this.store, this.apiService);
  }

  getAuthController() {
    return this.authController;
  }

  getApiService() {
    return this.apiService;
  }

  getBookmarkController() {
    return this.bookmarkController;
  }

  init() {
    this.loadStonks();
    this.authController.loadUser();
    this.render();
  }

  loadLeaderboard(metric: string): any {
    return this.apiService.loadLeaderboard(metric).then(response => response.data);
  }

  loadRating(user: string): any {
    return this.apiService.loadRating(user).then(response => response.data);
  }

  loadStonks() {
    return this.apiService.loadStonks().then(stonks => {
      this.store.dispatch(setStonks(stonks));
    }).catch(error => {
      console.error(error);
      this.store.dispatch(setError(error));
    });
  }

  loadTwits(symbol: string) {
    return this.apiService.loadTwits(symbol).then(response => response.data);
  }

  render() {
    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(<Root mainController={this} store={this.store}/>);
  }

}
