import { faGoogle, faYahoo } from "@fortawesome/free-brands-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

import { Stonk } from "../../model/stonk";
import Bookmark from "../widgets/bookmark";

class ActionAdapter {
  getTableHeader(title, sortStonks, sortColumn, asc) {
    return <p onClick={sortStonks}>
      {title}
    </p>;
  }

  getView(stonk: Stonk) {
    return (
      <div className='text-center inline-flex'>
        <div className='mx-1'>
          <Bookmark symbol={stonk.symbol} />
        </div>
        {
          stonk.overview !== undefined && (
            <>
              <Link to={stonk.symbol}>
                <FontAwesomeIcon className='mx-1' icon={faChartLine} />
              </Link>
              <a
                href={`https://www.google.com/finance/quote/${stonk.symbol}:${stonk.overview.Exchange}`}
                target='_blank' rel="noreferrer"
              >
                <FontAwesomeIcon className='mx-1' icon={faGoogle} />
              </a>
            </>
          )
        }
        <a
          href={`https://finance.yahoo.com/quote/${stonk.symbol}`}
          target='_blank' rel="noreferrer"
        >
          <FontAwesomeIcon className='mx-1' icon={faYahoo} />
        </a>
      </div>
    );
  }
}

export default ActionAdapter;
