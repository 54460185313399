import '../style/filters.scss';

import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { defaultValues } from '../../model/default-values';
import { presetList } from '../../model/presets';

import IndexFilter from './index-filter';
import MarketCapFilter from './market-cap-filter';
import MetricFilter from './metric-filter';
import Presets from './presets';
import PriceFilter from './price-filter';
import SectorFilter from './sector-filter';
import VolumeFilter from './volume-filter';

const Filters = ({ stonks, close }) => {
  const {
    setValue,
    reset,
    formState,
    getValues
  } = useFormContext();
  const values = getValues();

  const resetHandler = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    if (formState.isDirty === true) {
      setValue('presets', -1);
    }
  }, [formState.isDirty, setValue]);

  useEffect(() => {
    const presetValue = values.presets;
    if (presetValue >= 0) {
      const preset = presetList[presetValue];
      preset.filters.presets = presetValue;
      reset(preset.filters, { keepDirty: false });
    }
  }, [reset, values.presets]);

  return (
    <div className='filters fixed w-full h-full md:relative md:w-80'>
      <div className='filters__headers mb-4'>
        <h2 className='inline-flex text-lg'>Filters</h2>
        <div className='w-full flex justify-end items-center'>
          <div className='mr-2'>
            <Button variant='text' size='small' onClick={() => resetHandler()}>Reset</Button>
          </div>
          <div>
            <IconButton size='small' onClick={() => close()}><FontAwesomeIcon size='xs' icon={faXmark} /></IconButton>
          </div>
        </div>
      </div>
      <div className='filters__presets mb-4'>
        <Presets />
      </div>
      <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography>Range</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PriceFilter />
          <MarketCapFilter />
          <VolumeFilter />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography>Index</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IndexFilter stonks={stonks} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography>Sectors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SectorFilter stonks={stonks} />
        </AccordionDetails>
      </Accordion>

      {/* <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography>Industries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IndustryFilter stonks={stonks} />
        </AccordionDetails>
      </Accordion> */}

      <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography>Metrics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MetricFilter />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
