// ChatGPT baby
export function removeDefaultValues(defaultObj, userSelectedObj) {
  let newObj = {};
  let keys = Object.keys(userSelectedObj);
  for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (typeof userSelectedObj[key] === "object") {
          if (Array.isArray(userSelectedObj[key])) {
              if (defaultObj[key].length !== userSelectedObj[key].length || !defaultObj[key].every((val, index) => val === userSelectedObj[key][index])) {
                  newObj[key] = [...userSelectedObj[key]];
              }
          } else {
              let nestedObj = removeDefaultValues(defaultObj[key], userSelectedObj[key]);
              if (Object.keys(nestedObj).length > 0) {
                  newObj[key] = nestedObj;
              }
          }
      } else if (!(key in defaultObj) || userSelectedObj[key] !== defaultObj[key]) {
          newObj[key] = userSelectedObj[key];
      }
  }
  return newObj;
}

export function applyUserValues(defaultObj, userSelectedObj) {
  let newObj = {...defaultObj};
  let keys = Object.keys(userSelectedObj);
  for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (typeof userSelectedObj[key] === "object") {
          if (Array.isArray(userSelectedObj[key])) {
              if (defaultObj[key].length !== userSelectedObj[key].length || !defaultObj[key].every((val, index) => val === userSelectedObj[key][index])) {
                  newObj[key] = [...userSelectedObj[key]];
              }
          } else {
              newObj[key] = applyUserValues(defaultObj[key], userSelectedObj[key]);
          }
      } else if (key in defaultObj && userSelectedObj[key] !== defaultObj[key]) {
          newObj[key] = userSelectedObj[key];
      }
  }
  return newObj;
}
