import { useMemo } from 'react';
import { useMainController } from '../hooks/use-main-controller';
import '../style/tracker-page.scss';

import { useRouteMatch } from 'react-router-dom';
import BackButton from '../components/back-button';
import { usePromise } from '../hooks/use-promise';

const TrackerPage = () => {
  const match = useRouteMatch<{ user: string }>();
  const user = match.params.user;

  const mainController = useMainController();
  const ratingPromise = useMemo(() => mainController.loadRating(user), [mainController, user]);
  const rating = usePromise({
    promise: ratingPromise,
    deps: [user],
  });

  console.log('rating', rating);
  return (
    <div className='tracker-page'>
      <div className='tracker-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='tracker-page__container'>
      <div className='tracker-page__title'>{user}</div>
      <div className='tracker-page__profile'>
      <a href={`https://stocktwits.com/${user}`} target='_blank' rel="noreferrer" >Profile</a>
      </div>
        { rating !== null ?
          <div>
            <div>Total Count: {rating.count}</div>
            <div>
              <h2>Day</h2>
              <div>Wins: {rating.dayWins}</div>
              <div>Win Loss: {rating.dayWinLoss}</div>
              <div>Ratio: {rating.dayRatio}</div>
              <div>Play Quality: {rating.dayQuality}</div>
            </div>
            <div>
              <h2>Week</h2>
              <div>Wins: {rating.weekWins}</div>
              <div>Win Loss: {rating.weekWinLoss}</div>
              <div>Ratio: {rating.weekRatio}</div>
              <div>Play Quality: {rating.weekQuality}</div>
            </div>
          </div>
          : 'Loading' }
      </div>
    </div>
  );
};

export default TrackerPage;
