import { useMemo } from 'react';

import { useMainController } from '../hooks/use-main-controller';
import { usePromise } from '../hooks/use-promise';
import Twit from './twit';

const StockTwits = ({ symbol }) => {
  const mainController = useMainController();
  const stonkPromise = useMemo(() => mainController.loadTwits(symbol), [mainController, symbol]);
  const stockTwits = usePromise({
    promise: stonkPromise,
    deps: [symbol],
  });

  if (!stockTwits || stockTwits.length === 0) {
    return <div>No data available from StockTwits.</div>;
  }

  return (
    <div>
      {stockTwits?.messages.map((stockTwit, index) => (
        <Twit
          key={index}
          twit={stockTwit}
        />
      ))}
    </div>
  );
};

export default StockTwits;
