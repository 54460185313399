import '../style/ratio-average.scss';

import classNames from 'classnames';
import numeral from "numeral";

import { calcPercent, clamp, roundPrice } from '../../utils/math';
import pinGreen from '../assets/pin-green.svg';
import pinRed from '../assets/pin-red.svg';
import pinNeutral from '../assets/pin-neutral.svg';

const RatioAverage = ({ min, max, current, mid }) => {
  const currentPercent = clamp(0, 100, calcPercent(min, max, current));
  const midPercent = clamp(0, 100, calcPercent(min, max, mid));

  const classList = classNames(
    'ratio-average__current',
    { 'ratio-average--positive': currentPercent > midPercent },
    { 'ratio-average--negative': currentPercent < midPercent },
    { 'ratio-average--neutral': currentPercent === midPercent },
  );

  let pin = null;

  if (currentPercent > midPercent) {
    pin = pinGreen;
  } else if (currentPercent < midPercent) {
    pin = pinRed;
  } else {
    pin = pinNeutral;
  }

  min = roundPrice(min);
  max = roundPrice(max);

  return (
    <div className='ratio-average'>
      <div className='ratio-average__bar'>
        <div className='ratio-average__min'>{numeral(min).format('0.0a')}</div>
        <div
          className={classList}
          style={{ left: `${currentPercent}%` }}
        ><img src={pin} alt={current} title={current}/></div>
        <div className='ratio-average__max'>{numeral(max).format('0.0a')}</div>
      </div>
    </div>
  );
};

export default RatioAverage;
