const LastUpdate = ({ stonks }) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  let earliest = null;

  if (stonks !== null) {
    stonks.forEach(stock => {
      const lastDate = new Date(stock.createdAt);
      if (earliest === null || lastDate < earliest) {
        earliest = lastDate;
      }
    });
  }

  return (
    <div className='last-update'>
      {earliest !== null && 'Last Updated: ' + earliest.toLocaleDateString(undefined, options)}
    </div>
  )
};

export default LastUpdate;
