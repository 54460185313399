// ChatGPT baby
import { useState, useEffect } from 'react';

/**
 * Debounce component that will prevent the child component from re-rendering when the props change.
 * @param {Object} props - Component props.
 * @param {Number} props.wait - Time in milliseconds to wait before updating the child component with new props.
 * @param {Function} props.children - Child component that will be debounced.
 * @returns {ReactElement} Debounced child component.
 */
export const Debounce = ({ children, wait, props }) => {
  const [debouncedProps, setDebouncedProps] = useState(props);

  useEffect(() => {
    if (debouncedProps !== props) {
      const timeout = setTimeout(() => {
        setDebouncedProps(props);
      }, wait);

      return () => clearTimeout(timeout);
    }
  }, [debouncedProps, props, wait]);

  return children({ ...debouncedProps });
};
