import { Stonk } from "../../model/stonk";
import SortIcon from "../table/sort-icon";

class TextAdapter {
  selector: (stonk: Stonk) => any;
  constructor(selector: (stonk: Stonk) => any) {
    this.selector = selector;
  }

  getTableHeader(title, sortStonks, sortColumn: boolean, asc: boolean) {
    return <p className='cursor-pointer' onClick={sortStonks}>
      {title} <SortIcon sortColumn={sortColumn} asc={asc} />
    </p>;
  }

  getView(stonk: Stonk) {
    const value = this.selector(stonk);

    return <p className={'text-center'}>{value}</p>;
  }
}

export default TextAdapter;
