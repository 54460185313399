import { defaultValues } from "./default-values";
import { marketCapRange } from "./market-cap-range";
import { volumeRange } from "./volume-range";

export const presetList = [
  {
    label: 'Week Best Large Cap',
    filters: {
      ...defaultValues,
      metric: [
        ...defaultValues.metric,
        'weekAverage',
        'monthAverage',
        'twoMonthAverage',
        '50DayAverage',
        '200DayAverage',
      ],
      asc: false,
      marketCap: [4, marketCapRange.length - 1],
      sortColumn: 'deltaRatio',
    }
  },
  {
    label: 'Week Worst Large Cap',
    filters: {
      ...defaultValues,
      metric: [
        ...defaultValues.metric,
        'weekAverage',
        'monthAverage',
        'twoMonthAverage',
        '50DayAverage',
        '200DayAverage',
      ],
      asc: true,
      marketCap: [4, marketCapRange.length - 1],
      sortColumn: 'deltaRatio',
    }
  },
  {
    label: 'Week Best SP500',
    filters: {
      ...defaultValues,
      metric: [
        ...defaultValues.metric,
        'weekAverage',
        'monthAverage',
        'twoMonthAverage',
        '50DayAverage',
        '200DayAverage',
      ],
      asc: false,
      sp500: true,
      sortColumn: 'deltaRatio',
    }
  },
  {
    label: 'Week Worst SP500',
    filters: {
      ...defaultValues,
      metric: [
        ...defaultValues.metric,
        'weekAverage',
        'monthAverage',
        'twoMonthAverage',
        '50DayAverage',
        '200DayAverage',
      ],
      asc: true,
      sp500: true,
      sortColumn: 'deltaRatio',
    }
  },
  {
    label: 'Unusual Volume',
    filters: {
      ...defaultValues,
      metric: [
        ...defaultValues.metric,
        'volume',
      ],
      volume: [5, volumeRange.length - 1],
      asc: false,
      sortColumn: 'weekVolumeAverage',
    }
  }
];
