import { Chip } from '@mui/material';

import { Stonk } from "../../model/stonk";
import { formatText } from '../../utils/format-text';
import SortIcon from "../table/sort-icon";

const sectorStyles = {
  'ENERGY & TRANSPORTATION': { backgroundColor: '#5951EE', color: '#FFFFFF' },
  'FINANCE': { backgroundColor: '#00F3D7', color: '#000000' },
  'LIFE SCIENCES': { backgroundColor: '#C600CA', color: '#FFFFFF' },
  'MANUFACTURING': { backgroundColor: '#AEFF03', color: '#000000' },
  'REAL ESTATE & CONSTRUCTION': { backgroundColor: '#FF7C00', color: '#000000' },
  'TECHNOLOGY': { backgroundColor: '#0CC6E2', color: '#000000' },
  'TRADE & SERVICES': { backgroundColor: '#CF2850', color: '#FFFFFF' },
};

class SectorAdapter {
  selector: (stonk: Stonk) => any;
  constructor(selector: (stonk: Stonk) => any) {
    this.selector = selector;
  }

  getTableHeader(title, sortStonks, sortColumn: boolean, asc: boolean) {
    return <p className='cursor-pointer' onClick={sortStonks}>
      {title} <SortIcon sortColumn={sortColumn} asc={asc} />
    </p>;
  }

  getView(stonk: Stonk) {
    const value = this.selector(stonk);
    let sectorStyle = sectorStyles[stonk.overview.Sector];

    return <div className={'text-center'}>
      <Chip label={formatText(value)} sx={sectorStyle} size='small' />
    </div>;
  }
}

export default SectorAdapter;
