import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { createFormReference } from '../../utils/form-reference';

export const SignUpForm = ({ signUp }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState(null);
  const onSubmit = (data) => {
    setError(null);
    return signUp(data).catch((exception) =>
      setError(exception.message),
    );
  };

  return (
    <form id='sign-up-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='sign-up-form'>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              type='text'
              size='small'
              variant='outlined'
              label='First Name'
              {...createFormReference('firstName', register, errors)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              type='text'
              size='small'
              variant='outlined'
              label='Last Name'
              {...createFormReference('lastName', register, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type='text'
              size='small'
              variant='outlined'
              label='Display Name'
              {...createFormReference('displayName', register, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type='text'
              size='small'
              variant='outlined'
              label='Email'
              {...createFormReference('email', register, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type='password'
              size='small'
              variant='outlined'
              label='Password'
              {...createFormReference('password', register, errors)}
            />
          </Grid>
          {error !== null ? (
            <Grid item xs={12}>
              <Typography color='error'>{error}</Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox required {...register('tos')} />}
                label={<div>
                  <span>I accept the </span>
                  <Link className='link' to={'/terms-of-service'}>terms of service</Link>
                  <span> and </span>
                  <Link className='link' to={'/disclosures'}>disclosures</Link>
               </div>}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              size='small'
              type='submit'
            >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
