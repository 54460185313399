import '../style/twits.scss';

import { formatRelative } from 'date-fns';

import { faComment, faHeart, faLink, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Twit = ({ twit }) => {
  try {
    const {
      id,
      body,
      created_at,
      user: { username = '', name = '', avatar_url = '' } = {},
      source: { title = '' } = {},
      symbols = [],
      reshare_message: { reshared_count = 0 } = {},
      likes: { total = 0 } = {},
      conversation: { replies = 0 } = {},
      links = [],
      entities: { sentiment = '', chart = '' } = {},
    } = twit;

    const bodyElements = body.split(/(https?:\/\/[^\s]+|\$[a-z.]{2,})/gi).map((textFragment, index) => {
      if (textFragment.startsWith('$')) {
        return (
          <Link
            className='tweet__symbol'
            to={`/${textFragment.slice(1)}`}
            key={index}
          >
            {textFragment}
          </Link>
        );
      }
      const link = links.find(l => l.url === textFragment);
      if (link && link.shortened_url) {
        return <a href={link.url} target='_blank' rel='noopener noreferrer' className='tweet__link-highlight' key={index}>{link.shortened_expanded_url}</a>;
      }

      const dummy = document.createElement('div');
      dummy.innerHTML = textFragment;
      return <Fragment key={index}>{dummy.textContent}</Fragment>;
    });

    return (
      <div className='tweet flex w-full p-4 mb-4'>
        <a href={`https://stocktwits.com/${username}`} target='_blank' rel="noreferrer" >
          <img
            src={avatar_url}
            alt={username}
            className='tweet__avatar mr-6'
          />
        </a>
        <div className='tweet__content w-full'>
          <div className='tweet__header flex'>
            <div className='tweet__info w-full flex align-top mb-2'>
              <div className='tweet__user mr-4'>
                <div className='tweet__name'>
                  <a href={`https://stocktwits.com/${username}`} target='_blank' rel="noreferrer" >{name}</a>
                  <Link
                    className='tweet__rating'
                    to={`/tracker/user/${username}`}
                  >
                    [Rating]
                  </Link>
                </div>
              </div>
              <div className='tweet__date'>
                <a href={`https://stocktwits.com/neofeudal/message/${id}`} target='_blank' rel="noreferrer" >
                  {formatRelative(new Date(created_at), new Date())}
                </a>
              </div>
            </div>
          </div>
          <div className='tweet__body mb-4'>{bodyElements}</div>
          {sentiment?.basic && <div className={`tweet__sentiment tweet__sentiment--${sentiment.basic.toLowerCase()} mb-4`}>{sentiment.basic}</div>}
          {chart && (
            <a href={chart.original} target='_blank' rel="noreferrer">
              <img
                src={chart.large}
                alt='tweet-chart'
                className='tweet__chart'
              />
            </a>
          )}
          {links &&
            links.map((link) => (
              <div
                className='tweet__link'
                key={link.url}
              >
                <div className='tweet__link-meta'>
                  <a
                    href={link.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {link.image && (
                      <div className='justify-center flex'>
                        <img
                          src={link.image}
                          alt={link.title}
                        />
                      </div>
                    )}
                    {(link.title || link.description) &&
                      <div className='p-4'>
                        <p className='tweet__link-title'>{link.title}</p>
                        <p className='tweet__link-description'>{link.description}</p>
                      </div>
                    }
                  </a>
                </div>
              </div>
            ))}
          <div className='tweet__footer w-full flex justify-between mt-8 pr-20'>
            <div>
              <a href={`https://stocktwits.com/neofeudal/message/${id}`} target='_blank' rel="noreferrer" >
                <FontAwesomeIcon icon={faComment} />
                <p className='ml-2 inline'>{replies}</p>
              </a>
            </div>
            <div>
              <a href={`https://stocktwits.com/neofeudal/message/${id}`} target='_blank' rel="noreferrer" >
                <FontAwesomeIcon icon={faShare} />
                <p className='ml-2 inline'>{reshared_count}</p>
              </a>
            </div>
            <div>
              <a href={`https://stocktwits.com/neofeudal/message/${id}`} target='_blank' rel="noreferrer" >
                <FontAwesomeIcon icon={faHeart} />
                <p className='ml-2 inline'>{total}</p>
              </a>
            </div>
            <div>
              <a href={`https://stocktwits.com/neofeudal/message/${id}`} target='_blank' rel="noreferrer" >
                <FontAwesomeIcon icon={faLink} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.log(e);
    console.log('BROKEN FOR', twit);
    return <div>error</div>;
  }
};

export default Twit;
