export function objectToParams(key: string, object: any, url = window.location.search) {
  const urlParams = new URLSearchParams(url);

  if (Object.keys(object).length > 0) {
    const encoded = encodeObject(object);
    urlParams.set(key, encoded);
  } else {
    urlParams.delete(key);
  }

  return urlParams;
}

export function parseQuery(key: string, url = window.location.search) {
  const urlParams = new URLSearchParams(url);
  const value = urlParams.get(key);

  return decodeObject(value);
}

export function encodeObject(object: any) {
  const jsonString = JSON.stringify(object);
  const encoded = window.btoa(jsonString);

  return encoded;
}

export function decodeObject(value: string) {
  let object = {};
  try {
    if (value !== null) {
      const decoded = window.atob(value);
      object = JSON.parse(decoded);
    }
  } catch (e) {
    console.warn(e);
  }

  return object;
}
