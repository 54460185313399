import { faSort, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SortIcon = ({ column = null, sortColumn, asc }) => {
  let icon = faSort;

  if ((sortColumn === true || sortColumn === column) && asc === true) {
    icon = faCaretUp;
  } else if ((sortColumn === true || sortColumn === column) && asc === false) {
    icon = faCaretDown;
  }

  return <FontAwesomeIcon className='ml-2' icon={icon} />;
};

export default SortIcon;
