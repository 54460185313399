import classNames from "classnames";
import { Stonk } from "../../model/stonk";
import SortIcon from "../table/sort-icon";

class PercentAdapter {
  selector: (stonk: Stonk) => any;
  constructor(selector: (stonk: Stonk) => any) {
    this.selector = selector;
  }

  getTableHeader(title, sortStonks, sortColumn: boolean, asc: boolean) {
    return <p className='cursor-pointer' onClick={sortStonks}>
      {title} <SortIcon sortColumn={sortColumn} asc={asc} />
    </p>;
  }

  getView(stonk: Stonk) {
    const value = this.selector(stonk);
    const rounded = Math.round(value * 100) / 100;
    const classList = classNames(
      'text-center',
      { positive: value > 0 },
      { negative: value < 0 },
      { neutral: value === 0 }
    );
    return <p className={classList}>{rounded}%</p>;
  }
}

export default PercentAdapter;
