import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#202226',
      default: '#121316'
    },
    primary: {
      main: '#0CC6E2',
    },
    secondary: {
      main: '#AEFF03',
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#ffffff",
            border: '1px solid #0CC6E2',
            backgroundColor: 'transparent',
          },
          "&:hover": {
            color: '#ffffff',
            backgroundColor: 'transparent',
          }
        }
      }
    }
  }
});

export const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
}
