import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import { AppState } from "../../model/app-state";
import { useMainController } from "../hooks/use-main-controller";
import { useHistory } from "react-router-dom";
import { isUserLoggedIn } from "../../model/selector/is-user-logged-in";
import { getBookmarks } from "../../model/selector/get-bookmarks";

export const Bookmark = ({ symbol, bookmarks, loggedIn }) => {
  const mainController = useMainController();
  const history = useHistory();
  const bookmarkController = mainController.getBookmarkController();
  const bookmarked = loggedIn === true ? bookmarks.includes(symbol): false;
  const bookmark = () => {
    if (loggedIn === true) {
      if (bookmarked === true) {
        bookmarkController.removeBookmark(symbol);
      } else {
        bookmarkController.addBookmark(symbol);
      }
    } else {
      history.push('/login');
    }
  };

  return <div className='cursor-pointer' onClick={bookmark}>
    {bookmarked === true ?
      <FontAwesomeIcon icon={faBookmark} /> : <FontAwesomeIcon icon={faBookmarkRegular} />
    }
  </div>;
}

export default connect(() => {
  const bookmarkSelector = getBookmarks();
  const loggedInSelector = isUserLoggedIn();

  return (state: AppState) => {
    return {
      bookmarks: bookmarkSelector(state),
      loggedIn: loggedInSelector(state),
    };
  };
})(Bookmark);
