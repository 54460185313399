import '../style/not-found-page.scss';

import notFound from '../assets/confused-meme.gif';

import BackButton from '../components/back-button';

const NotFoundPage = () => {
  return (
    <div className='not-found-page mx-2'>
      <div className='not-found-page__back-button mt-4 ml-2'>
        <BackButton />
      </div>
      <div className='not-found-page__container'>
        <img src={notFound} className='not-found-page__image' alt="Not Found"/>
        <h1 className='not-found-page__title mt-4'>Stonk not found</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
