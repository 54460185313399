import Store from 'store';

/**
 * It's an abstraction for the `localStorage` API.
 * PersistedStorage uses store.js
 *
 * @module class-persisted-storage
 * @category PersistedStorage
 * @example
 * import {PersistedStorage} from 'vpe-utils/lib/web/persisted-storage';
 *
 * let persistedStorage = new PersistedStorage('namespace');
 * persistedStorage.setProperty('foo', 'bar');
 * console.log(persistedStorage.getProperty('foo'));
 * // Output
 * // bar
 */
/**
 * @alias module:class-persisted-storage
 */
class PersistedStorage {
  /**
   * @param {string} namespace string to prepend onto every key in this instance. optional.
   */
  private namespace;
  private store;
  constructor(namespace = null) {
    this.store = Store;
    this.namespace = namespace;
  }

  /**
   * Retrieves persisted property value for a key in the same namespace
   *
   * @param {string} key the key whose associated value is to be returned
   * @returns {object} stored property value
   */

  getProperty(key) {
    return this.store.get(this.namespaceKey(key));
  }

  /**
   * Determines if storage is enabled in the current environment
   *
   * @returns {boolean} true if storage is possible
   */
  isEnabled() {
    return this.store.enabled;
  }

  namespaceKey(key, separator = '_') {
    
    return this.namespace !== null ? this.namespace + separator + key : key;
  }

  /**
   * Removes persisted property value for a key in the same namespace
   *
   * @param {string} key the key whose associated value is to be removed
   * @returns {void}
   */
  removeProperty(key) {
    this.store.remove(this.namespaceKey(key));
  }

  /**
   * Sets a persisted property value with a key for the namespace
   *
   * @param {string} key the key whose associated value is to be set
   * @param {object} value value to be associated with the specified key
   * @returns {object} value
   */
  setProperty(key, value) {
    return this.store.set(this.namespaceKey(key), value);
  }
}
export { PersistedStorage };
