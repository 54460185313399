import { Action, Dispatch, Store } from 'redux';

import * as Constants from '../model/constants';
import { Logger } from './logger';
import { PersistedStorage } from './persisted-storage';

export class PersistMiddleware {
  logger: Logger;
  persistedStorage: PersistedStorage;
  actions: { [key: string]: string };
  handlers: {
    [key: string]: (store: Store, next: Dispatch, action: Action) => Action<unknown>;
  };
  constructor(actions) {
    this.logger = new Logger('PersistMiddleware');
    // TODO Find a shorter form for persistence handlers with the same efficiency and better readability
    this.actions = actions;
    this.persistedStorage = new PersistedStorage(
      Constants.PERSISTED_STORAGE_NAMESPACE,
    );

    // Create handlers for actions
    this.handlers = {};
    for (const key in actions) {
      const stateKey = actions[key];

      if (stateKey !== null) {
        const handler = (store: Store, next: Dispatch, action: Action) => {
          const result = next(action);
          const state = store.getState();
          const stateValue = state[stateKey];

          this.logger.log(`${stateKey} has been saved.`);
          this.persistedStorage.setProperty(stateKey, stateValue);

          return result;
        };

        this.handlers[key] = handler;
      }
    }

    // Create special handler to save all keys on reset
    for (const resetKey in actions) {
      const resetStateKey = actions[resetKey];

      if (resetStateKey === null) {
        const handler = (store: Store, next: Dispatch, action: Action) => {
          const result = next(action);
          const state = store.getState();

          for (const key in actions) {
            const stateKey = actions[key];
            if (stateKey !== null) {
              this.logger.log(`${stateKey} has been saved.`);
              this.persistedStorage.setProperty(stateKey, state[stateKey]);
            }
          }

          return result;
        };

        this.handlers[resetKey] = handler;
      }
    }
  }

  createMiddleware() {
    return (store) => (next) => (action) => {
      const handler = this.handlers[action.type];

      if (handler !== undefined && this.persistedStorage.isEnabled() === true) {
        return handler(store, next, action);
      } else {
        return next(action);
      }
    };
  }
}
