import { marketCapRange } from '../../model/market-cap-range';

import RangeSliderFilter from './range-slider-filter';

const MarketCapFilter = () => {
  return (
    <div className='market-cap-filter'>
      <p>Market Capitalization</p>
      <div className='flex flex-wrap w-full p-4'>
        <RangeSliderFilter name='marketCap' range={marketCapRange}/>
      </div>
    </div>
  )
};

export default MarketCapFilter;
