import '../style/graph.scss';

import { useEffect } from "react";
import { Stonk } from "../../model/stonk";

interface GraphProps {
  stonk: Stonk;
}

const Graph = ({ stonk }: GraphProps) => {
  const symbol = `${stonk.overview.Exchange}:${stonk.symbol}`

  useEffect(() => {
    if ('TradingView' in window) {
      new window.TradingView['widget'](
        {
          'autosize': true,
          symbol,
          'interval': 'D',
          'timezone': 'Etc/UTC',
          'theme': 'dark',
          'style': '1',
          'locale': 'en',
          'toolbar_bg': '#f1f3f6',
          'enable_publishing': false,
          'allow_symbol_change': false,
          'details': true,
          "studies": [
            "BB@tv-basicstudies"
          ],
          'container_id': 'graph__container'
        });
    }
  });

  return <div className='graph'>
    <div className='graph__container' id='graph__container'></div>
    <div className="tradingview-widget-copyright">
      <a href={`https://www.tradingview.com/symbols/${stonk.overview.Exchange}-${stonk.symbol}/`} rel="noopener noreferrer" target="_blank">
        <span className="blue-text">{stonk.symbol} stock chart</span></a> by TradingView
      </div>
  </div>;
}

export default Graph;
